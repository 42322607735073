import React from "react";
import moment from "moment";
import { Button } from "reactstrap";
// import { SiAuthentik } from "react-icons/si";
const AuthorizeButton = ({ item, authorized, onClick }) => {
  // Check if login_time exists and if it's after 9:30 AM
  const loginTime = item?.login_time ? moment(item?.login_time) : null;
  const isAuthorizedTime =
    loginTime &&
    loginTime.isAfter(
      moment().set({ hour: 9, minute: 30, second: 0, millisecond: 0 })
    );

  return (
    <div>
      {isAuthorizedTime && (
        <Button
          className={`btn btn-sm ${authorized ? "bg-success" : ""}`}
          onClick={() => onClick(item)} // Handle click with item.id
        >
          {authorized ? "Authorized" : "Authorize"}
        </Button>
        // <SiAuthentik onClick={() => onClick(item)}>
        //   {authorized ? "Authorized" : "Authorize"}
        // </SiAuthentik>
      )}
    </div>
  );
};

export default AuthorizeButton;
