import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar } from "reactstrap";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import StarIcon from "mdi-react/StarIcon";
import ReplyIcon from "mdi-react/ReplyIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import PrinterIcon from "mdi-react/PrinterIcon";
import PaperclipIcon from "mdi-react/PaperclipIcon";
import CloseIcon from "mdi-react/CloseIcon";
import { EmailBodyProps } from "@/shared/prop-types/EmailProps";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { UPDATE_LEAVE } from "../../../../../GraphQL/Mutation";
import { successMessage, warningMessage } from "../../../../ReactToastify";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

const Email = ({ email, onReply, onBack }) => {
  const history = useHistory();
  const [isDeny, setIsDeny] = useState(false);
  const [isQuickReplied, setIsQuickReplied] = useState(false);
  const [updateLeave] = useMutation(UPDATE_LEAVE);

  const onQuickReply = () => {
    setIsQuickReplied(true);
  };

  const approvedHandler = async (approvedData, type) => {
    if (type == "deny") {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Reason of deny",
        inputPlaceholder: "Type reason of deny...",
        confirmButtonText: "Deny",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        allowOutsideClick: false,
      });

      if (text) {
        const result = await updateLeave({
          variables: {
            ...approvedData,
            leave_status: "Denied",
            deny_reason: text,
          },
        });
        if (result?.data?.updateLeave?.result_code == "000") {
          successMessage("Leave application has been denied.", 3000);
          history.push("/leave/mail");
        }
        if (result?.data?.updateLeave?.result_code == "002") {
          warningMessage(result?.data?.updateLeave?.status, 3000);
        }
        onBack();
      }
      // await Swal.fire({
      //   title: 'Deny reason',
      //   input: 'textarea',
      //   confirmButtonText: "Deny",
      //   allowOutsideClick: false,
      //   showCancelButton: true

      // }).then(function (result) {

      //   console.log(result?.value);
      //   denyText = result?.value;
      //   if(!result?.value){
      //     Swal.fire({
      //       type: 'warning',
      //       html: 'cannot proceed without input'
      //   });
      //   }
      // })
      // const result = await updateLeave({
      //   variables: {
      //     ...approvedData,
      //     leave_status: "Denied",
      //     deny_reason: denyText
      //   }
      // })
      // if (result?.data?.updateLeave?.result_code == "000") {
      //   successMessage(result?.data?.updateLeave?.status, 3000);
      //   history.push("/leave/mail");
      // }
      // if (result?.data?.updateLeave?.result_code == "002") {
      //   warningMessage(result?.data?.updateLeave?.status, 3000);
      // }
      // onBack()
    }

    if (type == "approve") {
      const result = await updateLeave({
        variables: {
          ...approvedData,
          leave_status: "Approved",
        },
      });
      if (result?.data?.updateLeave?.result_code == "000") {
        successMessage(result?.data?.updateLeave?.status, 3000);
        history.push("/leave/mail");
      }
      if (result?.data?.updateLeave?.result_code == "002") {
        warningMessage(result?.data?.updateLeave?.status, 3000);
      }
      onBack();
    }
  };

  const onQuickReplyClose = () => {
    setIsQuickReplied(false);
  };

  // leave type for leave details show
  const leaveTypeList = [
    {
      value: 1,
      label: `Casual`,
    },
    {
      value: 2,
      label: `Anual`,
    },
    {
      value: 3,
      label: `Sick`,
    },
    {
      value: 4,
      label: `Menstrual`,
    },
    {
      value: 5,
      label: `Maternity`,
    },
    {
      value: 6,
      label: `Reimburse`,
    },
  ];

  return (
    <div className="inbox__email inbox__content">
      <div className="inbox__email-header">
        <div className="inbox__email-left">
          <button
            className="inbox__email-back"
            type="button"
            aria-label="email back button"
            onClick={onBack}
          >
            <ArrowLeftIcon />
          </button>
          <div className="inbox__email-ava">
            <img src={email?.user?.images} alt="" />
          </div>
          <div className="inbox__email-info">
            <h5 className="inbox__email-name">
              {email?.user?.name}
              <StarIcon
                className={`inbox__favorite${email.favorite ? " active" : ""}`}
              />
            </h5>
            <p className="inbox__email-email">{email?.user?.email}</p>
          </div>
        </div>
        <div className="inbox__email-right">
          <p className="inbox__email-time">
            {moment(new Date(Number(email?.createdAt))).fromNow()}
          </p>
        </div>
      </div>
      <div className="inbox__email-body">
        <h4>
          Leavey type:{" "}
          {
            leaveTypeList?.find((item) => item?.value == email?.leave_type)
              ?.label
          }
        </h4>
        <h6>Leave Start</h6>
      </div>

      <ButtonToolbar className="inbox__email-reply-btns mt-4 justify-content-end">
        <div>
          <Button
            onClick={() => {
              approvedHandler(email, "deny");
            }}
            size="sm"
            color="danger"
          >
            Deny
          </Button>
        </div>
        <div>
          <Button
            onClick={() => approvedHandler(email, "approve")}
            size="sm"
            color="primary"
          >
            Approve
          </Button>
        </div>
      </ButtonToolbar>
      {/* {email.attachment
        ? (
          <div className="inbox__email-attachment">
            <h5 className="inbox__email-attachment-title" dir="ltr">Attachment (2 file 12Mb):</h5>
            {email.attachment.map(item => (
              <div key={`index_${item.name}_${item.time}`} className="inbox__email-attachment-link">
                <PaperclipIcon /><a href={item.link} download dir="ltr">{item.name} ({item.size})</a>
              </div>
            ))}
          </div>
        ) : ''}
      <div className="inbox__email-reply">
        <textarea className="inbox__email-reply-textarea" onFocus={onQuickReply} />
        {!isQuickReplied
          ? <p className="inbox__email-reply-placeholder">Click here to <span>reply</span></p>
          : (
            <div>
              <button className="inbox__email-reply-close" type="button" onClick={onQuickReplyClose}>
                <CloseIcon />
              </button>
              <ButtonToolbar className="inbox__email-reply-btns">
                <Button size="sm" color="primary">Send</Button>
                <button className="inbox__email-reply-btn-full" type="button" onClick={onReply}>
                  Go to full answer form
                </button>
              </ButtonToolbar>
            </div>
          )}
      </div> */}
    </div>
  );
};

Email.propTypes = {
  email: EmailBodyProps.isRequired,
  onReply: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Email;
