import React, { useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";

const DownloadAttendanceReport = ({ login_date }) => {
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status

  // Function to trigger file download
  const downloadReport = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      // Send a GET request to download the file
      const response = await axios.get(
        `http://localhost:3000/api/download-attendance?login_date=${login_date}`,
        {
          responseType: "blob", // Important for binary file downloads
        }
      );

      // Create a new Blob from the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // MIME type for Excel
      });

      // Use file-saver to save the file
      saveAs(blob, `Attendance_Report_${login_date}.xlsx`);
    } catch (error) {
      console.error("Error downloading the report:", error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  return (
    <button
      className="btn btn-primary"
      onClick={downloadReport}
      disabled={isLoading} // Disable button while loading
    >
      {isLoading ? "Downloading..." : "Attendance Report"}{" "}
      {/* Button text changes based on loading state */}
    </button>
  );
};

export default DownloadAttendanceReport;
