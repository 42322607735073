import { Card, CardBody, Col, Table, Button, Container, Row } from "reactstrap";
import TabPlusIcon from "mdi-react/TabPlusIcon";
import { useHistory } from "react-router";
import UserDesignationTable from "./UserDesignationTable";
import { decryption } from "../HelperFunctions";
import BreadCrumb from "../../shared/components/BreadCrumb/BreadCrumb";

const UserDesignation = () => {
  const history = useHistory();
  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );
  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "User Designation ",
    },
  ];
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3 className="page-title">User Designation list</h3>
            </Col>
            <Col md={6}>
              {permissions?.includes("create_designation") && (
                <Button
                  onClick={() => history.push("/user/new-designation")}
                  className="float-right"
                  color="primary"
                >
                  <TabPlusIcon />
                  {/* <Link to="/make/request">Make a request</Link> */}
                  New Designation
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <UserDesignationTable />
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserDesignation;
