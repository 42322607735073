import React from "react";
import { Modal } from "reactstrap";

const NoticeDetailsModal = ({ isOpen, toggle, notice }) => {
  return (
    <Modal toggle={toggle} isOpen={isOpen} centered>
      <div
        style={
          {
            // padding: "20px",
            // borderRadius: "10px",
            // border: "2px solid #f9f9f9", //
            // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }
        }
      >
        {/* Date and Day Name */}
        <p
          style={{
            fontSize: "14px",
            color: "#555",
            marginBottom: "15px",
          }}
        >
          <strong>Publish Date:</strong>{" "}
          {notice?.createdAt
            ? new Intl.DateTimeFormat("en-US", {
                weekday: "long",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }).format(new Date(Number(notice?.createdAt)))
            : "Date not available"}
        </p>

        {/* Notice Name */}
        <h3 style={{ marginBottom: "15px" }}>Notice Name: {notice?.name}</h3>

        {/* Publisher and Category */}
        <div
          className="d-flex align-items-center mb-4"
          style={{ fontSize: "14px", color: "#333" }}
        >
          <span className="mr-2" style={{ marginRight: "15px" }}>
            <strong>Published By:</strong> {notice?.user?.name}
          </span>
          <span>
            <strong>Notice Category:</strong> {notice?.category}
          </span>
        </div>

        {/* Notice Description */}
        <strong>Notice Description:</strong>
        <div
          dangerouslySetInnerHTML={{ __html: notice?.description }}
          style={{
            marginTop: "10px",
            padding: "15px",
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            color: "#555",
            lineHeight: "1.6",
            border: "1px solid #ddd",
          }}
        />
      </div>
    </Modal>
  );
};

export default NoticeDetailsModal;
