import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.css";
import i18n from "i18next";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { connect, Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "../../scss/app.scss";
import initAuth0 from "../../shared/components/auth/withAuth0";
import { config as i18nextConfig } from "../../translations";
import BASE_URL from "../../utils/api/baseUrl";
import Router from "./Router";
import ScrollToTop from "./ScrollToTop";
import store from "./store";
i18n.init(i18nextConfig);
import "react-datepicker/dist/react-datepicker.css";

const ThemeComponent = ({ children, themeName }) => {
  const theme = createMuiTheme({
    palette: {
      type: themeName === "theme-dark" ? "dark" : "light",
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ThemeComponent.propTypes = {
  children: PropTypes.node.isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect((state) => ({
  themeName: state.theme.className,
}))(ThemeComponent);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = from([errorLink, new HttpLink({ uri: `${BASE_URL}/graphql` })]);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage?.getItem("token")}` || null,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, link),
});

const App = () => {
  useEffect(() => {
    window.addEventListener("load", initAuth0);
  }, []);
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter basename="/office-management">
          <I18nextProvider i18n={i18n}>
            <ScrollToTop>
              <ConnectedThemeComponent>
                <ToastContainer />
                <Router />
              </ConnectedThemeComponent>
            </ScrollToTop>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
