import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { SelectField } from "@/shared/components/form/Select";
import FormField from "../../../components/FormField";
// import useUserDropdownsQuery from "@/GraphQL/hooks/queries/useUserDropdownsQuery";
// import useDepartmentDropdownsQuery from "@/GraphQL/hooks/queries/useDepartmentDropdownsQuery";
// import useAttendanceSummaryQuery from "@/GraphQL/hooks/queries/useAttendanceSummaryQuery";
import useUserDropdownsQuery from "../../../../../GraphQL/hooks/queries/useUserDropdownsQuery";
import useDepartmentDropdownsQuery from "../../../../../GraphQL/hooks/queries/useDepartmentDropdownsQuery";
import useAttendanceSummaryQuery from "../../../../../GraphQL/hooks/queries/useAttendanceSummaryQuery";
import { useHistory } from "react-router-dom";
import {
  objectToQueryParams,
  paramsToObject,
} from "../../../../../utils/helpers";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AttendanceReportSearchForm = ({ setResults, setSearchData }) => {
  const { handleSubmit, control, watch, reset, errors } = useForm();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const paramsObject = paramsToObject(location.search);
  console.log(paramsObject);
  const [leaveDeductionForm, setLeaveDeductionForm] = useState({
    leaveTypes: [],
    users: [],
    departments: [],
  });

  /**
   * Load Attendance summary
   */
  const LoadAttendanceSummary = useAttendanceSummaryQuery({
    variables: {
      input: {},
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  // ** employee dropdown query
  const LoadEmployeeDropdownData = useUserDropdownsQuery({
    skip: !watch("department")?.value,
    variables: {
      input: {
        dept_id: watch("department")?.value,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  // ** department dropdown query
  const LoadDepartmentDropdownData = useDepartmentDropdownsQuery({
    variables: {
      input: {},
    },
    notifyOnNetworkStatusChange: true,
  });

  // ** set employees for dropdown
  useEffect(() => {
    if (LoadEmployeeDropdownData?.data?.users) {
      setLeaveDeductionForm((prevState) => ({
        ...prevState,
        users: LoadEmployeeDropdownData?.data?.users,
      }));
    }
  }, [LoadEmployeeDropdownData?.data]);

  // ** set department for dropdown
  useEffect(() => {
    if (LoadDepartmentDropdownData?.data?.departments) {
      setLeaveDeductionForm((prevState) => ({
        ...prevState,
        departments: LoadDepartmentDropdownData?.data?.departments,
      }));
    }
  }, [LoadDepartmentDropdownData?.data]);

  const onSubmit = async (data) => {
    const input = {
      start_date: new Date(startDate).getTime(),
      end_date: new Date(endDate).getTime(),
      user_id: data?.employee?.value,
    };

    // const queryString = objectToQueryParams(input);
    // history.push(`?${queryString}`);

    const searchedData = await LoadAttendanceSummary?.refetch({
      input,
    });

    setSearchData(input);
    setResults(searchedData?.data?.attendanceSummary);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <Col md={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">Department</span>
          <div className="form__form-group-field ">
            <FormField
              name="department"
              errors={errors}
              control={control}
              component={SelectField}
              options={leaveDeductionForm?.departments}
              placeholder="Select department"
              rules={{ required: "This is required field" }}
            />
          </div>
        </div>
      </Col>
      <Col md={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">Employee</span>
          <div className="form__form-group-field ">
            <FormField
              name="employee"
              errors={errors}
              control={control}
              component={SelectField}
              options={leaveDeductionForm?.users}
              placeholder="Select employee"
              rules={{ required: "This is required field" }}
            />
          </div>
        </div>
      </Col>
      <Col xs={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">{`Date Range`}</span>
          <div className="form__form-group-field leave-calender">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/yyyy - dd/mm/yyyy"
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
            />
          </div>
        </div>
      </Col>

      <Col xs={12}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <ButtonToolbar className="form__button-toolbar">
            <Button
              onClick={() => history.push("/")}
              className="btn-sm ml-3"
              color="danger"
              type="submit"
            >
              Cancel
            </Button>
            <Button
              disabled={LoadAttendanceSummary?.loading ? true : false}
              className="btn-sm ml-3"
              color="primary"
              type="submit"
            >
              {LoadAttendanceSummary?.loading ? "Searching..." : "Search"}
            </Button>
          </ButtonToolbar>
        </div>
      </Col>
    </form>
  );
};

export default AttendanceReportSearchForm;
