// import {
//   changeMobileSidebarVisibility,
//   changeSidebarVisibility
// } from "@/redux/actions/sidebarActions";
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from "../../redux/actions/sidebarActions";
import {
  changeThemeToDark,
  changeThemeToLight,
} from "../../redux/actions/themeActions";
import {
  BlocksShadowsProps,
  CustomizerProps,
  RoundBordersProps,
  RTLProps,
  SidebarProps,
  ThemeProps,
} from "@/shared/prop-types/ReducerProps";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./topbar/Topbar";
import TopbarWithNavigation from "./topbar_with_navigation/TopbarWithNavigation";
const Layout = ({
  dispatch,
  customizer,
  isAboveError,
  sidebar,
  theme,
  rtl,
  roundBorders,
  blocksShadows,
}) => {
  const [address, setAddress] = useState(
    JSON.stringify(localStorage?.getItem("address"))
  );
  const [modal, setModal] = useState(address?.length > 10 ? false : true);

  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  const themeDark = useSelector((state) => state.theme.className);

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };

  const layoutClass = classNames({
    layout: true,
    "layout--collapse": sidebar.collapse,
    "layout--top-navigation": customizer.topNavigation,
  });

  return (
    <div className={layoutClass}>
      {customizer.topNavigation ? (
        <TopbarWithNavigation
          changeMobileSidebarVisibility={mobileSidebarVisibility}
        />
      ) : (
        <Topbar
          changeMobileSidebarVisibility={mobileSidebarVisibility}
          changeSidebarVisibility={sidebarVisibility}
        />
      )}
      <Sidebar
        sidebar={sidebar}
        changeToDark={changeToDark}
        changeToLight={changeToLight}
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        topNavigation={customizer.topNavigation}
      />
    </div>
  );
};

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
  customizer: CustomizerProps.isRequired,
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
  roundBorders: RoundBordersProps.isRequired,
  blocksShadows: BlocksShadowsProps.isRequired,
};

export default withRouter(
  connect((state) => ({
    customizer: state.customizer,
    sidebar: state.sidebar,
    theme: state.theme,
    rtl: state.rtl,
    roundBorders: state.roundBorders,
    blocksShadows: state.blocksShadows,
  }))(Layout)
);
