import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Container,
  Row,
  ButtonToolbar,
  Modal,
} from "reactstrap";
import SearchForm from "./components/SearchForm";
import AttendanceTable from "./components/AttendanceTable";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_ADDITIONAL_INFO_BY_USER_ID,
  GET_ATTENDANCE_BY_DATE_RFID,
  GET_USER_ATTENDANCE,
  SEARCH_ATTENDANCE_BETWEEN_DATE,
  SEARCH_ATTENDANCE_BETWEEN_DATE_AND_RFID,
} from "../../GraphQL/Queries";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import jwt from "jsonwebtoken";
import moment from "moment";
import { decryption } from "../HelperFunctions";
import MoonLoader from "react-spinners/MoonLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import { CSVLink } from "react-csv";
import { MdOutlineDownloading } from "react-icons/md";
import ReactExport from "react-export-excel";
import {
  IsThereWeekend,
  loginInfo,
  userAttendenceObject,
} from "../../utils/helpers";
import AttendanceForm from "./components/AttendanceForm";
import axios from "axios";
import {
  CREATE_ATTENDANCE_BY_WEB,
  LOGOUT_ATTENDANCE,
} from "../../GraphQL/Mutation";
import Swal from "sweetalert2";
import useGetWorkingDayQuery from "../../GraphQL/hooks/queries/useGetWorkingDayQuery";
import { useHistory } from "react-router-dom";
import DownloadAttendanceReport from "../UserManagement/DownloadAttendanceReport";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Attendance = () => {
  const [workingDays, setWorkingDays] = useState({});
  const [allEmployees, setAllEmployees] = useState([]);
  const [isHomeOffice, setIsHomeOffice] = useState(false);
  const [attendanceToggle, setAttendanceToggle] = useState(false);
  const [loginStatus, setLoginStatus] = useState("logout");
  const loggedInfo = loginInfo();
  const [manualAttendanceToggle, setManualAttendanceToggle] = useState(false);
  const history = useHistory();
  //   ** get working day query

  // get all user attendance
  // const getUserByAttendance = useQuery(GET_ALL_USER_BY_ATTENDANCE, {
  //   variables: {
  //     login_date: moment(new Date()).format("YYYY-MM-DD"),
  //   },
  // });
  const getUserByAttendance = useQuery(GET_USER_ATTENDANCE, {
    variables: {
      login_date: moment(new Date()).format("YYYY-MM-DD"),
    },
  });

  const userLoginStatus = useQuery(GET_ATTENDANCE_BY_DATE_RFID, {
    skip: !loggedInfo?.id,
    variables: {
      user_id: Number(loggedInfo?.id),
      login_date: moment(new Date()).format("YYYY-MM-DD"),
    },
  });

  useEffect(() => {
    userLoginStatus?.refetch();
    setLoginStatus(
      userLoginStatus?.data?.getAllAttendanceByDateAndRfid?.login_status
    );
  }, [
    loginStatus,
    userLoginStatus?.data?.getAllAttendanceByDateAndRfid,
    attendanceToggle,
  ]);

  const [createAttendanceByWeb] = useMutation(CREATE_ATTENDANCE_BY_WEB);
  const [logoutAttendance] = useMutation(LOGOUT_ATTENDANCE);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  //add spinner fro loading
  let [color, setColor] = useState("rgb(54, 215, 183)");
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  // roles
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));

  const token = localStorage?.getItem("token");
  let userID;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    userID = decoded?.id;
  });
  // login user information
  const loginUserInfo = useQuery(GET_ALL_ADDITIONAL_INFO_BY_USER_ID, {
    variables: { user_id: userID },
  });
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  // get previous date of month fron now start
  const prevDateOfMonth = new Date();
  const month = prevDateOfMonth.getMonth();
  prevDateOfMonth.setMonth(prevDateOfMonth.getMonth() - 1);
  while (prevDateOfMonth.getMonth() === month) {
    prevDateOfMonth.setDate(prevDateOfMonth.getDate() - 1);
  }
  // last weeek
  const firstDay = new Date();
  const [filteredAttendance, setFilteredAttendance] = useState([]);

  const [isLoadingAttendance, setIsLoadingAttendance] = useState(false);
  const previousweek = new Date(firstDay.getTime() - 7 * 24 * 60 * 60 * 1000);
  // const [fromDate, setFromDate] = useState(previousweek);
  const [fromDate, setFromDate] = useState(null);
  const [lastMonth, setLastmonth] = useState(prevDateOfMonth);
  const [isViewResult, setIsViewResult] = useState(false);

  const [lastWeek, setLastweek] = useState(previousweek);
  const [toDate, setToDate] = useState(null);
  // const [toDate, setToDate] = useState(new Date());
  const [allAttendances, setAllAttendances] = useState([]);
  // const LoadAllAttendcesByDate = useQuery(GET_ALL_ATTENDANCES_BY_DATE);
  // const LoadAllAttendces = useQuery(GET_ALL_ATTENDANCES);
  const [filterByUsername, setFilterByUsername] = useState(null);
  const [filterByAny, setFilterByAny] = useState(null);
  const [onSearch, setOnSearch] = useState(false);
  const [dailyAttendances, setDailyAttendances] = useState([]);

  const searchByDateAndRfid = useQuery(
    SEARCH_ATTENDANCE_BETWEEN_DATE_AND_RFID,
    {
      skip: true,
    }
  );
  const searchByDate = useQuery(SEARCH_ATTENDANCE_BETWEEN_DATE_AND_RFID, {
    skip: true,
  });
  // const searchByDate = useQuery(SEARCH_ATTENDANCE_BETWEEN_DATE, {
  //   skip: true,
  // });
  const LoadWorkingDayData = useGetWorkingDayQuery({
    variables: {
      input: {
        start_date: new Date(moment(new Date()).format("YYYY-MM-DD")),
        end_date: new Date(moment(new Date()).format("YYYY-MM-DD")),
      },
    },
  });
  // console.log(
  //   "getUserByAttendance?.data?.getUserByAttendance:",
  //   getUserByAttendance?.data?.getUserByAttendance
  // );
  const searchOnchangeHandler = (event) => {
    setFilterByAny(event.target.value);
    setFilterByUsername(null);
  };

  if (
    roles?.includes("System Admin") ||
    roles?.includes("Human Resource (HR)") ||
    roles?.includes("Head Of Department")
  ) {
    useEffect(async () => {
      setIsLoadingAttendance(true);
      const result = await searchByDate.refetch({
        startedDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      setIsLoadingAttendance(false);
      setDailyAttendances(
        getUserByAttendance?.data?.getUserByAttendance?.map((item, index) =>
          userAttendenceObject(item, index)
        )
      );
      // setDailyAttendances(result?.data?.search_attendance_between_date);

      searchByDate?.refetch();
    }, [
      searchByDate.data?.search_attendance_between_date_and_rfid?.length,
      getUserByAttendance?.data?.getUserByAttendance,
    ]);
  } else {
    useEffect(async () => {
      setIsLoadingAttendance(true);
      const result = await searchByDateAndRfid.refetch({
        startedDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
        user_id: loggedInfo?.id,
      });
      setIsLoadingAttendance(false);
      setDailyAttendances(
        result?.data?.search_attendance_between_date_and_rfid
      );
      searchByDateAndRfid.refetch();
    }, [
      searchByDateAndRfid?.data?.search_attendance_between_date_and_rfid,
      attendanceToggle,
    ]);
  }

  useEffect(async () => {
    setWorkingDays(LoadWorkingDayData?.data);
  }, [LoadWorkingDayData?.data]);

  // sorted function
  Array.prototype.sortBy = function (p) {
    return this.slice(0).sort(function (a, b) {
      return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
    });
  };

  const [filteredResults, setFilteredResults] = useState([]);

  const searchResult = async (from, to, employeeId, searchType) => {
    setIsLoadingAttendance(true);
    setFilterByUsername(employeeId);

    if (
      roles?.includes("System Admin") ||
      roles?.includes("Human Resource (HR)") ||
      roles?.includes("Head Of Department")
    ) {
      if (!!searchType || !!employeeId) {
        if (employeeId) {
          const result = await searchByDateAndRfid.refetch({
            startedDate: moment(from).format("YYYY-MM-DD"),
            endDate: moment(to).format("YYYY-MM-DD"),
            user_id: employeeId,
          });
          setIsLoadingAttendance(false);
          setFilteredAttendance(
            result?.data?.search_attendance_between_date_and_rfid
          );
        }
        if (searchType == 1) {
          if (employeeId) {
            const result = await searchByDateAndRfid.refetch({
              startedDate: moment(lastWeek).format("YYYY-MM-DD"),
              endDate: moment(new Date()).format("YYYY-MM-DD"),
              user_id: employeeId,
            });
            setIsLoadingAttendance(false);
            setFilteredAttendance(
              result?.data?.search_attendance_between_date_and_rfid
            );
          } else {
            const result = await searchByDate.refetch({
              startedDate: moment(lastWeek).format("YYYY-MM-DD"),
              endDate: moment(new Date()).format("YYYY-MM-DD"),
            });
            setIsLoadingAttendance(false);
            setFilteredAttendance(
              result?.data?.search_attendance_between_date_and_rfid
            );
          }
        }
        if (searchType == 2) {
          if (employeeId) {
            const result = await searchByDateAndRfid.refetch({
              startedDate: moment(lastMonth).format("YYYY-MM-DD"),
              endDate: moment(new Date()).format("YYYY-MM-DD"),
              user_id: employeeId,
            });
            setIsLoadingAttendance(false);
            setFilteredAttendance(
              result?.data?.search_attendance_between_date_and_rfid
            );
          } else {
            const result = await searchByDate.refetch({
              startedDate: moment(lastMonth).format("YYYY-MM-DD"),
              endDate: moment(new Date()).format("YYYY-MM-DD"),
            });
            setIsLoadingAttendance(false);
            setFilteredAttendance(
              result?.data?.search_attendance_between_date_and_rfid
            );
          }
        }
      } else {
        const result = await searchByDate.refetch({
          startedDate: moment(from).format("YYYY-MM-DD"),
          endDate: moment(to).format("YYYY-MM-DD"),
        });
        setIsLoadingAttendance(false);
        setFilteredAttendance(
          result?.data?.search_attendance_between_date_and_rfid
        );
      }
    } else {
      const result = await searchByDateAndRfid.refetch({
        startedDate: moment(from).format("YYYY-MM-DD"),
        endDate: moment(to).format("YYYY-MM-DD"),
        rfid: loginUserInfo?.data?.getAllAdditionalInfoByUserId?.rfid,
      });
      setIsLoadingAttendance(false);
      setFilteredAttendance(
        result?.data?.search_attendance_between_date_and_rfid
      );
    }
  };

  const [excelData, setExcelData] = useState([]);
  useEffect(() => {
    setExcelData(
      filteredAttendance?.map((item, index) => {
        return {
          id: index + 1,
          name: item.employeeInfo?.user?.name,
          rfid: item.rfid,
          user_ip: item.user_ip,
          login_date: item.login_date,
          login_time: item.login_time,
          logout_device: item.logout_device,
          logout_time: item.logout_time,
        };
      })
    );
  }, [filteredAttendance?.length]);

  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );

  // home office attendance
  const placeAttendanceHandler = async () => {
    let returnValue;
    await axios
      .get("https://www.cloudflare.com/cdn-cgi/trace")
      .then(function (response) {
        returnValue = response?.data
          .trim()
          .split("\n")
          .reduce(function (obj, pair) {
            pair = pair.split("=");
            return (obj[pair[0]] = pair[1]), obj;
          }, {});
      });
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to place an attendance?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await createAttendanceByWeb({
          variables: {
            user_id: loggedInfo?.id,
            time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            user_ip: returnValue?.ip,
          },
        });
        getUserByAttendance?.refetch();
        setAttendanceToggle((prevState) => !prevState);
        if (result?.data?.createAttendanceByWeb?.result_code == "000") {
          Swal.fire("Success!", "Your attendance has been placed.", "success");
        } else {
          Swal.fire(
            "Failed!",
            result?.data?.createAttendanceByWeb?.status,
            "error"
          );
        }
      }
    });
  };
  useEffect(() => {
    if (localStorage?.getItem("home_office")) {
      setIsHomeOffice(localStorage?.getItem("home_office"));
    }
  }, [attendanceToggle]);

  const officeLeaveHandler = async () => {
    let returnValue;
    await axios
      .get("https://www.cloudflare.com/cdn-cgi/trace")
      .then(function (response) {
        returnValue = response?.data
          .trim()
          .split("\n")
          .reduce(function (obj, pair) {
            pair = pair.split("=");
            return (obj[pair[0]] = pair[1]), obj;
          }, {});
      });
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to leave office?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await logoutAttendance({
          variables: {
            user_id: loggedInfo?.id,
            time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            user_ip: returnValue?.ip,
          },
        });
        getUserByAttendance?.refetch();
        setAttendanceToggle((prevState) => !prevState);
        Swal.fire("Success!", "Successfully logut.", "success");
      }
    });
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col xs={6} md={4}>
              <h3 className="page-title">Attendance</h3>
            </Col>
            <Col xs={6} md={8}>
              {excelData?.length > 0 ? (
                <ExcelFile
                  element={
                    <button className="btn ml-2 btn-sm btn-primary float-right">
                      Report <MdOutlineDownloading className="mb-1" />
                    </button>
                  }
                >
                  <ExcelSheet
                    data={excelData}
                    name={`Office_Attendance_${moment(new Date()).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}`}
                  >
                    <ExcelColumn label="SL" value="id" />
                    <ExcelColumn label="Name" value="name" />
                    <ExcelColumn label="RFID" value="rfid" />
                    <ExcelColumn label="User IP" value="user_ip" />
                    <ExcelColumn label="Login Date" value="login_date" />
                    <ExcelColumn label="Login Time" value="login_time" />
                    <ExcelColumn label="Logout Device" value="logout_device" />
                    <ExcelColumn label="Logout Time" value="logout_time" />
                  </ExcelSheet>
                </ExcelFile>
              ) : null}
              {permissions?.includes("create_place_attendance") &&
              loginStatus == "login" ? (
                <button
                  onClick={() => {
                    officeLeaveHandler();
                  }}
                  className="btn btn-sm btn-danger float-right ml-2"
                >
                  - Leave Office
                </button>
              ) : (
                <button
                  onClick={() => {
                    placeAttendanceHandler();
                  }}
                  className="btn btn-sm btn-success float-right ml-2"
                >
                  + Today Attendance
                </button>
              )}

              {permissions?.includes("create_manual_attendance") && (
                <button
                  onClick={() => {
                    toggle();
                  }}
                  className="btn btn-sm btn-success float-right ml-2"
                >
                  + Manual Attendance
                </button>
              )}

              {permissions?.includes("attendance_report") && (
                <button
                  onClick={() => {
                    history.push("/attendance/reports");
                  }}
                  className="btn btn-sm btn-success float-right ml-2"
                >
                  Report
                </button>
              )}
              {/* try code */}
              {permissions?.includes("attendance_report") && (
                <DownloadAttendanceReport
                  login_date={moment(new Date()).format("YYYY-MM-DD")}
                />
              )}
            </Col>
          </Row>
          <SearchForm
            setFilterByUsername={setFilterByUsername}
            filterByUsername={filterByUsername}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            filterByAny={filterByAny}
            userID={userID}
            roles={roles}
            searchResult={searchResult}
            setFilteredResults={setFilteredResults}
            filteredAttendance={filteredAttendance}
            setOnSearch={setOnSearch}
            setFilteredAttendance={setFilteredAttendance}
            setIsViewResult={setIsViewResult}
            isViewResult={isViewResult}
            allEmployees={allEmployees}
            setAllEmployees={setAllEmployees}
            data={getUserByAttendance?.data}
            todayCount={getUserByAttendance?.data?.getUserByAttendance?.map(
              (item, index) => userAttendenceObject(item, index)
            )}
            workingDays={workingDays}
            LoadWorkingDayData={LoadWorkingDayData}
          />

          {isViewResult ? (
            <AttendanceTable
              roles={roles}
              isLoadingAttendance={isLoadingAttendance}
              allAttendances={filteredAttendance}
            />
          ) : (
            <AttendanceTable
              roles={roles}
              isLoadingAttendance={isLoadingAttendance}
              allAttendances={dailyAttendances}
            />
          )}
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <AttendanceForm
          toggle={toggle}
          allEmployees={allEmployees}
          refetch={getUserByAttendance?.refetch}
        />
      </Modal>
    </Col>
  );
};

export default Attendance;
