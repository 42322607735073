// import {
//   changeThemeToDark,
//   changeThemeToLight,
// } from "@/redux/actions/themeActions";
import {
  changeThemeToDark,
  changeThemeToLight,
} from "../../../redux/actions/themeActions";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { decryption } from "../../HelperFunctions";
import TopbarLeaveRequest from "../components/topbar/TopbarLeaveRequest";
import TopbarProfile from "../components/topbar/TopbarProfile";
import TopbarSidebarButton from "../components/topbar/TopbarSidebarButton";
import ToggleTheme from "../customizer/ToggleTheme";

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const dispatch = useDispatch();
  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };
  const theme = useSelector((state) => state.theme);
  const permission = localStorage?.getItem("permissions")
    ? decryption(JSON.parse(localStorage?.getItem("permissions")))
    : null;
  return (
    <div className="topbar">
      <div className="topbar__left">
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />

        <Link
          className="d-none d-md-block"
          to="/"
          onClick={(e) => {
            // Check if the current path is "/"
            if (window.location.pathname === "/office-management/") {
              // If it is, prevent default navigation and reload the page
              e.preventDefault();
              window.location.reload();
            }
            // If the current path is not "/", the Link component will handle the navigation
          }}
        >
          <h3 className="account__title mt-3">
            <span className="account__logo">OFFICE MANAGEMENT</span>
          </h3>
        </Link>
      </div>
      <div className="topbar__right">
        <div className="topbar__right-over">
          <div className="mt-2 mr-3">
            <ToggleTheme
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              theme={theme}
            />
          </div>
          {permission?.includes("view_notification") && <TopbarLeaveRequest />}
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
