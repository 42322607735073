import { Card, CardBody, Col, Table, Button, Container, Row } from "reactstrap";
import TabPlusIcon from "mdi-react/TabPlusIcon";
import HistoryTable from "./HistoryTable";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_DEPARTMENT,
  GET_ALL_LEAVES,
  GET_ALL_PENALTY,
  GET_ALL_PENALTY_BY_DEPT,
  GET_ALL_PENALTY_BY_USER,
} from "../../../GraphQL/Queries";
import { useEffect, useState } from "react";
import { decryption, leaveTypeListForEmail } from "../../HelperFunctions";
import PenaltyTable from "./PenaltyTable";
import { loginInfo } from "../../../utils/helpers";
import { reviewReasons } from "./PenaltyForm";
import { attendanceTypeArray } from "../../../utils/utilities";
import BreadCrumb from "../../../shared/components/BreadCrumb/BreadCrumb";

const Penalty = () => {
  // variables
  let loading = false;
  const history = useHistory();
  const loggedInfo = loginInfo();
  const isUpdate = history?.location?.state?.update_id;
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );
  // states
  const [penalty, setPenalty] = useState([]);

  // queries
  // // for system admin and Human Resource (HR)
  if (
    roles?.includes("System Admin") ||
    roles?.includes("System Manager") ||
    roles?.includes("Human Resource (HR)")
  ) {
    const loadAllPenalty = useQuery(GET_ALL_PENALTY);
    loading = loadAllPenalty?.loading;
    // update penalty state for system admin and Human Resource (HR)
    useEffect(() => {
      if (loadAllPenalty?.data?.getAllPenalty?.length > 0) {
        setPenalty(loadAllPenalty?.data?.getAllPenalty);
      }
      loadAllPenalty?.refetch();
    }, [loadAllPenalty?.data?.getAllPenalty]);
  }

  // for  head of department
  if (roles?.includes("Head Of Department")) {
    const loadAllPenalty = useQuery(GET_ALL_PENALTY_BY_DEPT, {
      skip: !loggedInfo?.id,
      variables: {
        user_id: loggedInfo?.id,
      },
    });
    loading = loadAllPenalty?.loading;
    // update penalty state for head of department
    useEffect(() => {
      if (loadAllPenalty?.data?.getAllPenaltyByDept?.length > 0) {
        setPenalty(loadAllPenalty?.data?.getAllPenaltyByDept);
      }
      loadAllPenalty?.refetch();
    }, [loadAllPenalty?.data?.getAllPenaltyByDept]);
  }

  // for employee
  if (roles?.includes("Employee")) {
    const penaltyByUser = useQuery(GET_ALL_PENALTY_BY_USER, {
      variables: {
        user_id: loggedInfo?.id,
      },
    });
    loading = penaltyByUser?.loading;
    // update penalty state for employee
    useEffect(() => {
      if (penaltyByUser?.data?.getAllPenaltyByUser?.length > 0) {
        setPenalty(penaltyByUser?.data?.getAllPenaltyByUser);
      }
      penaltyByUser?.refetch();
    }, [penaltyByUser?.data?.getAllPenaltyByUser, isUpdate]);
  }
  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "Penalty",
    },
  ];
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3 className="page-title">Penalty list</h3>
            </Col>
          </Row>
          <Row>
            <PenaltyTable
              loading={loading}
              roles={roles}
              tableData={penalty?.map((item, index) => ({
                ...item,
                indexId: index + 1,
                attendance_reason: reviewReasons?.find(
                  (reasonItem) =>
                    reasonItem?.value ==
                    item?.reviewPenaltyInfo?.attendance_reason
                )?.label,
                attendance_type: attendanceTypeArray?.find(
                  (typeItem) =>
                    typeItem?.value == item?.reviewPenaltyInfo?.attendance_type
                )?.label,
                leave_name: leaveTypeListForEmail?.find(
                  (typeItem) => typeItem?.value == item?.leave_id
                )?.label,
              }))}
            />
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Penalty;
