import React, { useEffect } from "react";
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import { SelectField } from "@/shared/components/form/Select";
import FormField from "./FormField";
import { useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import {
  checkWithDate,
  dateRangeArray,
  isExistApllication,
  IsThereWeekend,
  loginInfo,
} from "../../../utils/helpers";
import moment from "moment";
import { CREATE_LEAVE, UPDATE_LEAVE } from "../../../GraphQL/Mutation";
import { useMutation, useQuery } from "@apollo/client";
import { successMessage, warningMessage } from "../../ReactToastify";
import { GET_ALL_LEAVES_BY_USER_ID } from "../../../GraphQL/Queries";
import BreadCrumb from "../../../shared/components/BreadCrumb/BreadCrumb";

const LeaveForm = () => {
  const history = useHistory();
  const updateLeaveData = history?.location?.state?.leave;
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      reason: updateLeaveData?.id ? updateLeaveData?.leave_reason : "",
    },
  });
  const loggedInfo = loginInfo();
  const [attendanceDate, setAttendanceDate] = useState([
    updateLeaveData?.id ? new Date(updateLeaveData?.start_date) : null,
    updateLeaveData?.id ? new Date(updateLeaveData?.end_date) : null,
  ]);
  const [startDate, endDate] = attendanceDate;
  const [attendanceDay, setAttendanceDay] = useState(0);

  // mutations
  const [updateLeave] = useMutation(UPDATE_LEAVE);
  const [createLeave] = useMutation(CREATE_LEAVE);

  // queries
  const getAllLeavesByUserId = useQuery(GET_ALL_LEAVES_BY_USER_ID, {
    skip: !loggedInfo?.id,
    variables: {
      user_id: Number(loggedInfo?.id),
    },
  });

  // check if already exist leave application
  const existLeaveApplication = checkWithDate(
    getAllLeavesByUserId?.data?.getAllLeavesByUserId?.map((item) => ({
      start_date: moment(new Date(item?.start_date)).format("YYYY-MM-DD"),
      end_date: moment(new Date(item?.end_date)).format("YYYY-MM-DD"),
      id: item?.id,
    }))
  );

  // attendance type array
  const attendanceTypeArray = [
    {
      value: 101,
      label: `Work From Home`,
    },
    {
      value: 102,
      label: `On Field Duty`,
    },
  ];

  // weekend check for attendance
  const IsThereWeekend = (startDate, endDate) => {
    // date format: new Date("2022-03-01");
    let weekendDates = [];
    const initialState = startDate;
    initialState.setDate(startDate.getDate());
    for (
      let date = initialState;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      if (date.getDay() < 5) {
        weekendDates.push(new Date(date.toString()));
      }
    }
    return weekendDates;
  };

  const onSubmit = async (value) => {
    const formData = {
      user_id: Number(loggedInfo?.id),
      rfid: null,
      leave_reason: value?.reason,
      leave_type: Number(value?.attendance_type?.value),
      images: null,
      leave_label: null,
      start_date: String(attendanceDate[0]),
      end_date: String(attendanceDate[1]),
    };

    //  create application for attendance
    const newApplicationBody = `
            <!DOCTYPE html>
                <html>
                <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                </head>
                <body>
                    <p>Dear ${loggedInfo?.additionalInfo?.departmentInfo?.user?.name
                      ?.split(" ")
                      ?.slice(0, 2)
                      ?.join(" ")} sir,</p>    
                    <p>I am writing this email for ${
                      attendanceTypeArray?.find(
                        (item) => item?.value == formData?.leave_type
                      )?.label
                    }. I will be taking attendance following date: </p>    
                    <table style="border-collapse: collapse; margin: 25px 0; font-size: 0.9em; font-family: sans-serif; min-width: 400px; box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);">
                        <thead>
                            <tr style="background-color: #009879; color: #ffffff; text-align: left;">
                                <th style="padding: 12px 15px;">Start</th>
                                <th style="padding: 12px 15px;">End</th>
                                <th style="padding: 12px 15px;">Day</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="border-bottom: 1px solid #dddddd;">
                                <td style="padding: 12px 15px;">${moment(
                                  formData?.start_date
                                ).format("DD MMMM YYYY")}</td>
                                <td style="padding: 12px 15px;">${moment(
                                  formData?.end_date
                                ).format("DD MMMM YYYY")}</td>
                                <td style="padding: 12px 15px;">${
                                  IsThereWeekend(
                                    new Date(formData?.start_date),
                                    new Date(formData?.end_date)
                                  )?.length
                                } ${
      IsThereWeekend(
        new Date(formData?.start_date),
        new Date(formData?.end_date)
      )?.length > 1
        ? "Days"
        : "Day"
    }</td>
                            </tr>
                        </tbody>
                    </table>
                    <p><b>Reason</b>: <br /> ${formData?.leave_reason}</p>
                    <p>Best Regards,<br />
                    ${loggedInfo?.name} <br/> ${loggedInfo?.email}</p>
                    <a href="https://office.ipageglobal.com/leave/mail">Dashboard here</a>
                </body>
            </html>`;

    // exist any previous application checking within selected date
    const existApplicationId = [];
    const apllicantDateArray = dateRangeArray(
      moment(new Date(attendanceDate[0])).format("YYYY-MM-DD"),
      moment(new Date(attendanceDate[1])).format("YYYY-MM-DD")
    );
    apllicantDateArray?.map((existDate) => {
      existLeaveApplication?.map((item) => {
        if (item?.application_date == existDate) {
          existApplicationId.push(item?.application_id);
        }
      });
    });
    if ([...new Set(existApplicationId)]?.length > 0 && !updateLeaveData?.id) {
      // if exist any previous application within selected date
      isExistApllication(
        existApplicationId,
        getAllLeavesByUserId?.data?.getAllLeavesByUserId
      );
    } else {
      // if not exist any previous application within selected date
      if (updateLeaveData?.id) {
        const result = await updateLeave({
          variables: {
            ...formData,
            id: updateLeaveData?.id,
            user_id: updateLeaveData?.user_id,
          },
        });
        if (result?.data?.updateLeave?.result_code == "000") {
          successMessage(result?.data?.updateLeave?.status, 3000);
          history.push("/leave/history");
        }
        if (result?.data?.updateLeave?.result_code == "002") {
          warningMessage(result?.data?.updateLeave?.status, 3000);
        }
      } else {
        const result = await createLeave({
          variables: {
            ...formData,
            subject_email: `[${
              attendanceTypeArray?.find(
                (item) => item?.value == formData?.leave_type
              )?.label
            }] Application for ${
              attendanceTypeArray?.find(
                (item) => item?.value == formData?.leave_type
              )?.label
            }`,
            text_email: `Please check office management dashboard.`,
            html_email: newApplicationBody,
            from_email: loggedInfo?.email,
            // to_email: 'tanvirahammed.ipage@gmail.com',
            to_email: loggedInfo?.additionalInfo?.departmentInfo?.user?.email,
          },
        });
        if (result?.data?.createLeave?.result_code == "000") {
          successMessage(result?.data?.createLeave?.status, 3000);
          history.push("/leave/history");
        }
        if (result?.data?.createLeave?.result_code == "002") {
          warningMessage(result?.data?.createLeave?.status, 3000);
        }
      }
    }
  };
  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "Home/Field Office Apply ",
    },
  ];
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Col md={12}>
            <h3 className="page-title">
              Application for <abbr title="Work From Home">WFH</abbr> /{" "}
              <abbr title="On Job Duty">OD</abbr>
            </h3>
          </Col>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Col md={6} className="mx-auto">
              <Row>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Attendance</span>
                    <div className="form__form-group-field leave-calender">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select date"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setAttendanceDate(update);
                        }}
                        showWeekNumbers
                      />
                    </div>
                    {attendanceDay > 0 ? (
                      <span className="text-success">
                        <strong>
                          You are choosen {attendanceDay}{" "}
                          {attendanceDay > 1 ? "days" : "day "} for attendance.
                        </strong>
                      </span>
                    ) : null}
                    {/* <span className="text-danger"><strong>You are not eligible for  days leave</strong></span> */}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Attendance Type
                    </span>
                    <div className="form__form-group-field ">
                      <FormField
                        name="attendance_type"
                        control={control}
                        component={SelectField}
                        options={attendanceTypeArray}
                        defaultValue={attendanceTypeArray?.find(
                          (item) => item?.value == updateLeaveData?.leave_type
                        )}
                        placeholder="Select Attendance Type"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">Reason:</label>
                    <div className="form__form-group-field">
                      <FormField
                        name="reason"
                        component="textarea"
                        control={control}
                        type="text"
                        placeholder={`Write your reason for attendance`}
                        style={{ minHeight: "125px" }}
                        // defaultValue={updateLeaveData?.leave_reason}
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={12}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        type="button"
                        onClick={() => history.push("/leave/history")}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                      <Button color="primary" type="submit">
                        Apply
                      </Button>
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LeaveForm;
