import { useQuery } from "@apollo/client";
import { LEAVE_DEDUCTIONS } from "../../Queries";

export default (props) => {
  const { data, refetch, loading, error } = useQuery(LEAVE_DEDUCTIONS, {
    ...props,
  });
  const datas = data?.leaveDeductions;

  return { data: data?.leaveDeductions, loading, refetch, error };
};
