import { Card, CardBody, Col, Table, Button, Container, Row } from "reactstrap";
import TabPlusIcon from "mdi-react/TabPlusIcon";
import DeptListTable from "./CompanyListTable";
import { useHistory } from "react-router";
import { decryption } from "../../../HelperFunctions";
import CompanyListTable from "./CompanyListTable";
import BreadCrumb from "../../../../shared/components/BreadCrumb/BreadCrumb";

const Company = () => {
  const history = useHistory();
  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );
  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "Company ",
    },
  ];
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3 className="page-title">Company list</h3>
            </Col>
            <Col md={6}>
              {permissions?.includes("create_company") && (
                <Button
                  onClick={() => history.push("/company/new")}
                  className="float-right"
                  color="primary"
                >
                  <TabPlusIcon />
                  {/* <Link to="/make/request">Make a request</Link> */}
                  New Company
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <CompanyListTable />
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Company;
