import React from "react";
import { UserProfile } from "../../../../../shared/components/user";
import { ageCalculator, dateTime } from "../../../../../utils/helpers";

const AttendanceReportUserProfile = ({ data }) => {
  const additionalInfo = data?.additionalInfo;

  const { years, months, days } = ageCalculator(
    new Date(additionalInfo?.joining_date)
  );
  const profile = {
    name: data?.name,
    dept: additionalInfo?.departmentInfo?.name,
    desig: additionalInfo?.designationInfo?.name,
    image: data?.images,
  };

  const maleLeave = [
    {
      label: "Casual",
      value: additionalInfo?.casual,
    },
    {
      label: "Sick",
      value: additionalInfo?.sick,
    },
    {
      label: "Anual",
      value: additionalInfo?.anual,
    },
    {
      label: "Reimburse",
      value: additionalInfo?.reward,
    },
  ];

  const femaleLeave = [
    ...maleLeave,
    {
      label: "Maternity",
      value: additionalInfo?.maternity,
    },
    {
      label: "Menstrual",
      value: additionalInfo?.menstrual,
    },
  ];
  const Userdata = [
    {
      title: "Contact",
      data: [
        {
          label: "Email",
          value: data?.email,
        },
        {
          label: "Phone",
          value: data?.phone,
        },
      ],
    },
    {
      title: "Address",
      data: [
        {
          label: "Village",
          value: data?.village,
        },
        {
          label: "Union",
          value: data?.unionInfo?.name,
        },
        {
          label: "Upazilla",
          value: data?.upazillaInfo?.name,
        },
        {
          label: "District",
          value: data?.districtInfo?.name,
        },
        {
          label: "Division",
          value: data?.divisionInfo?.name,
        },
      ],
    },
    {
      title: "Office Info",
      data: [
        {
          label: "Login Id",
          value: data?.username,
        },
        {
          label: "Office ID",
          value: additionalInfo?.office_id,
        },
        {
          label: "RFID",
          value: additionalInfo?.rfid,
        },
        {
          label: "Joining Date",
          value:
            dateTime(
              new Date(additionalInfo?.joining_date).getTime(),
              "DD/MM/YYYY"
            ) + `(${years}Y ${months}M ${days}D)`,
        },
      ],
    },
    {
      title: "Leave Left",
      data: data?.gender === "Male" ? maleLeave : femaleLeave,
    },
  ];

  return <UserProfile data={Userdata} profile={profile} />;
};

export default AttendanceReportUserProfile;
