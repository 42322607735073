import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import {
  Table,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  ButtonToolbar,
  Col,
} from "reactstrap";
import { useHistory } from "react-router";
import { Pagination } from "@mui/material";
import { usePagination, loginInfo } from "../../../utils/helpers";
import { decryption } from "../../HelperFunctions";
import TableDataLoading from "../../../utils/HelperComponent/TableDataLoading";
import { badgeColor, actionStatusCode } from "../../../utils/utilities";
import {
  CREATE_ATTENDANCE_BY_ACCEPT_PENALTY,
  DEDUCT_LEAVE_BY_PENALTY,
} from "../../../GraphQL/Mutation";
import Swal from "sweetalert2";
import axios from "axios";
import { GET_ALL_USER } from "../../../GraphQL/Queries";

const PenaltyTable = ({ loading, tableData, deleteHandler, roles }) => {
  const history = useHistory();
  // state
  const [penaltyView, setPenaltyView] = useState({});

  // material pagination
  let [page, setPage] = useState(1);
  const loggedInfo = loginInfo();
  const PER_PAGE = 10;
  const count = Math.ceil(tableData?.length / PER_PAGE);
  const pageData = usePagination(tableData, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };
  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );

  // modal
  const [modal, setModal] = useState(false);
  const modalHandler = () => {
    setModal((prevSate) => !prevSate);
  };

  // queries
  const loadAllUser = useQuery(GET_ALL_USER);

  // mutations
  const [deductLeaveByPenalty, deniedStatus] = useMutation(
    DEDUCT_LEAVE_BY_PENALTY
  );
  const [createAttendanceByAcceptPenalty, acceptStatus] = useMutation(
    CREATE_ATTENDANCE_BY_ACCEPT_PENALTY
  );

  // leave deduct by denied
  const deniedHandler = async (penalty_id) => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Reason of deny",
      inputPlaceholder: "Type reason of deny...",
      confirmButtonText: "Deny",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      allowOutsideClick: false,
    });
    if (text) {
      const result = await deductLeaveByPenalty({
        variables: {
          penalty_id: Number(penalty_id),
          created_by: Number(loggedInfo?.id),
          denied_reason: text,
        },
      });
      if (result?.data?.deductLeaveByPenalty?.result_code == "000") {
        Swal.fire(
          "Deducted!",
          result?.data?.deductLeaveByPenalty?.status,
          "success"
        );
        // redirect to holydays list
        history.push({ pathname: "/leave/penalty" });
      }
      if (result?.data?.deductLeaveByPenalty?.result_code == "002") {
        Swal.fire(
          "Error!",
          result?.data?.deductLeaveByPenalty?.status,
          "error"
        );
      }
    }
  };

  // accept handler : pre attendance create
  const acceptHandler = async (penaltyView) => {
    let returnValue;
    await axios
      .get("https://www.cloudflare.com/cdn-cgi/trace")
      .then(function (response) {
        returnValue = response?.data
          .trim()
          .split("\n")
          .reduce(function (obj, pair) {
            pair = pair.split("=");
            return (obj[pair[0]] = pair[1]), obj;
          }, {});
      });
    const formData = {
      user_id: Number(penaltyView?.user_id),
      login_date: penaltyView?.penalty_date,
      user_ip: returnValue?.ip,
      reason: penaltyView?.attendance_reason,
      created_user_id: Number(loggedInfo?.id),
      type: Number(penaltyView?.reviewPenaltyInfo?.attendance_type),
      created_by: Number(loggedInfo?.id),
      penalty_id: Number(penaltyView?.id),
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Accept",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await createAttendanceByAcceptPenalty({
          variables: formData,
        });
        if (
          result?.data?.createAttendanceByAcceptPenalty?.result_code == "000"
        ) {
          Swal.fire(
            result?.data?.createAttendanceByAcceptPenalty?.status,
            "",
            "success"
          );
          Swal.fire(
            "Accepted!",
            result?.data?.createAttendanceByAcceptPenalty?.status,
            "success"
          );
        }
        if (
          result?.data?.createAttendanceByAcceptPenalty?.result_code == "002"
        ) {
          Swal.fire(
            result?.data?.createAttendanceByAcceptPenalty?.status,
            "",
            "error"
          );
          Swal.fire(
            "Error!",
            result?.data?.createAttendanceByAcceptPenalty?.status,
            "error"
          );
        }
        history.push("/leave/penalty");
      }
    });
  };

  return (
    <Col>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th style={{ width: "100px" }}>#</th>
            <th>Name</th>
            <th>Penalty Date</th>
            <th>Leave Deducted</th>
            <th>Status</th>
            <th>Penalty Created</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <TableDataLoading colSpan={7} />
          ) : tableData?.length ? (
            pageData?.currentData()?.map((item, index) => (
              <tr
                onClick={() => {
                  modalHandler();
                  setPenaltyView(item);
                }}
                key={item?.id}
              >
                <th style={{ width: "40px" }} scope="row">
                  {item?.indexId}
                </th>
                <td>
                  {item?.penaltyUserInfo?.name} (
                  {item?.penaltyUserInfo?.additionalInfo?.office_id})
                </td>
                <td>{moment(item?.penalty_date).format("DD MMMM YYYY")}</td>
                <td>{item?.leave_name ? "One " + item?.leave_name : "—"}</td>
                <td>
                  {item?.penalty_status ? (
                    <Badge
                      color={
                        badgeColor?.find(
                          (status) => status?.code == item?.penalty_status
                        )?.name
                      }
                    >
                      {
                        actionStatusCode?.find(
                          (status) => status?.code == item?.penalty_status
                        )?.name
                      }
                    </Badge>
                  ) : (
                    "—"
                  )}
                </td>
                <td>
                  {item?.createdAt
                    ? moment(new Date(Number(item?.createdAt))).format(
                        "DD MMMM YYYY, h:mm a"
                      )
                    : "—"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center text-success">
                <b>There is no penalty.</b>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {tableData?.length > 5 ? (
        <Pagination count={count} page={page} onChange={handleChange} />
      ) : null}

      {/* modal for penalty view in details */}
      <Modal toggle={modalHandler} isOpen={modal} backdrop="static">
        <ModalHeader toggle={modalHandler}>
          <div className="inbox__email-left">
            <div className="inbox__email-ava mr-3">
              <img
                src={
                  penaltyView?.penaltyUserInfo?.images
                    ? penaltyView?.penaltyUserInfo?.images
                    : "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"
                }
                alt=""
              />
            </div>
            <div className="inbox__email-info">
              <h5 className="inbox__email-name" style={{ fontSize: 20 }}>
                {penaltyView?.penaltyUserInfo?.name} (
                {penaltyView?.penaltyUserInfo?.additionalInfo?.office_id})
              </h5>
              <p className="inbox__email-email mt-1" style={{ fontSize: 14 }}>
                {penaltyView?.penaltyUserInfo?.email}
              </p>
            </div>
          </div>
        </ModalHeader>
        {penaltyView?.reviewPenaltyInfo ||
        penaltyView?.user_id == loggedInfo?.id ||
        roles?.includes("Employee") ? (
          <ModalBody>
            {roles?.includes("System Admin") ||
            roles?.includes("Human Resource (HR)") ||
            roles?.includes("Head Of Department") ? (
              penaltyView?.user_id == loggedInfo?.id &&
              !penaltyView?.reviewPenaltyInfo ? (
                <p className="mb-1">
                  {moment(new Date(Number(penaltyView?.createdAt))).format(
                    "dddd, Do MMMM YYYY, h:mm:ss a"
                  )}
                </p>
              ) : (
                <p className="mb-1">
                  {moment(
                    new Date(Number(penaltyView?.reviewPenaltyInfo?.createdAt))
                  ).format("dddd, Do MMMM YYYY, h:mm:ss a")}
                </p>
              )
            ) : (
              <p className="mb-1">
                {moment(new Date(Number(penaltyView?.createdAt))).format(
                  "dddd, Do MMMM YYYY, h:mm:ss a"
                )}
              </p>
            )}
            <div className="flex justify-content-between">
              <h4>
                {roles?.includes("System Admin") ||
                roles?.includes("Human Resource (HR)") ||
                roles?.includes("Head Of Department") ? (
                  penaltyView?.user_id == loggedInfo?.id ? (
                    <b>Penalty notices for regular non office attendance.</b>
                  ) : (
                    <b>Review application for regular non office attendance.</b>
                  )
                ) : (
                  <b>Penalty notices for regular non office attendance.</b>
                )}
              </h4>
              <div>
                {penaltyView?.penalty_status ? (
                  <Badge
                    color={
                      badgeColor?.find(
                        (status) => status?.code == penaltyView?.penalty_status
                      )?.name
                    }
                  >
                    {
                      actionStatusCode?.find(
                        (status) => status?.code == penaltyView?.penalty_status
                      )?.name
                    }
                  </Badge>
                ) : (
                  "—"
                )}
              </div>
            </div>
            <p>
              <b>Non-attendance date: </b>
              {moment(penaltyView?.penalty_date).format("DD MMMM YYYY")}
            </p>
            {penaltyView?.user_id == loggedInfo?.id ||
            roles?.includes("Employee") ? (
              <p>
                This is a penalty notices for regular non office attendance. The
                system will automatically cut off your one leave if your are not
                able to make an application/review within 24 hours. To review
                your non-office attendance click the review button.
              </p>
            ) : null}
            {penaltyView?.attendance_type ? (
              <p>
                <b>Attendance type: </b>
                {penaltyView?.attendance_type}
              </p>
            ) : null}
            {penaltyView?.attendance_reason ? (
              <p>
                <b>Attendance reason: </b>
                {penaltyView?.attendance_reason}
              </p>
            ) : null}

            {/* if penalty review status 1002 = in progress */}
            {penaltyView?.penalty_status == 1002 &&
            roles?.includes("Employee") ? (
              <div className="mt-3 bg-secondary text-white rounded px-3 py-2">
                <h4>Your review application in under review!</h4>
                <p>
                  at{" "}
                  {moment(
                    new Date(Number(penaltyView?.reviewPenaltyInfo?.createdAt))
                  ).format("dddd, Do MMMM YYYY, h:mm:ss a")}
                </p>
              </div>
            ) : null}

            {penaltyView?.penalty_status == 1004 ? (
              <div className="mt-3 bg-danger text-white rounded px-3 py-2">
                <h4>Your application has been denied!</h4>
                <i>
                  <p>
                    By{" "}
                    {
                      penaltyView?.reviewPenaltyInfo?.penaltyReviewUserInfo
                        ?.name
                    }{" "}
                    (
                    {
                      penaltyView?.reviewPenaltyInfo?.penaltyReviewUserInfo
                        ?.username
                    }
                    ) at{" "}
                    {moment(
                      new Date(
                        Number(penaltyView?.reviewPenaltyInfo?.updatedAt)
                      )
                    ).format("dddd, Do MMMM YYYY, h:mm:ss a")}
                  </p>
                </i>
                <p>
                  Your one {penaltyView?.leave_name} leave has been deducted.
                </p>
                <p>
                  <b>Reason:</b> <br />{" "}
                  {penaltyView?.reviewPenaltyInfo?.denied_reason}
                </p>
              </div>
            ) : null}
            {/* if application resolve/approved */}
            {penaltyView?.penalty_status == 1003 ? (
              <div className="mt-3 bg-success text-white rounded px-3 py-2">
                <h4>Your application has been approved!</h4>
                <i>
                  <p>
                    By{" "}
                    {
                      loadAllUser?.data?.getUser?.find(
                        (item) => item?.id == penaltyView?.created_by
                      )?.name
                    }{" "}
                    (
                    {
                      loadAllUser?.data?.getUser?.find(
                        (item) => item?.id == penaltyView?.created_by
                      )?.username
                    }
                    ) at{" "}
                    {moment(new Date(Number(penaltyView?.updatedAt))).format(
                      "dddd, Do MMMM YYYY, h:mm:ss a"
                    )}
                  </p>
                </i>
              </div>
            ) : null}
          </ModalBody>
        ) : (
          <span className="text-center text-danger mt-5">
            There is no review application against{" "}
            {moment(penaltyView?.penalty_date).format("DD MMMM YYYY")}
          </span>
        )}
        <ModalFooter className="flex justify-content-end align-items-center border-0">
          <ButtonToolbar className="mt-4 justify-content-end">
            <div>
              {permissions?.includes("deny_review_penalty") ? (
                penaltyView?.penalty_status == 1002 ? (
                  <Button
                    onClick={() => deniedHandler(penaltyView?.id)}
                    color="danger"
                    className="btn-sm mb-0"
                  >
                    Deny
                  </Button>
                ) : null
              ) : null}
              {permissions?.includes("accept_review_penalty") ? (
                penaltyView?.penalty_status == 1002 ? (
                  <Button
                    onClick={() => acceptHandler(penaltyView)}
                    color="primary"
                    className="btn-sm mb-0 ml-3"
                  >
                    Accept
                  </Button>
                ) : null
              ) : null}
              {permissions?.includes("review_penalty") ? (
                penaltyView?.penalty_status == 1001 &&
                loggedInfo?.id == penaltyView?.user_id ? (
                  <Button
                    onClick={() =>
                      history.push({
                        pathname: `/leave/review/${penaltyView?.id}/${penaltyView?.penalty_date}`,
                        state: {
                          data: penaltyView,
                        },
                      })
                    }
                    color="primary"
                    className="btn-sm mb-0 ml-3"
                  >
                    Review
                  </Button>
                ) : null
              ) : null}
            </div>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>
    </Col>
  );
};

export default PenaltyTable;
