import React, { useEffect, useState } from "react";
const permission = JSON.parse(localStorage?.getItem("permissions"));
import jwt from "jsonwebtoken";
import CryptoJS from "crypto-js";

// ========================encrypt-decrypt-start=========================
const key = `${process.env.SECRET_KEY}`;
// Encrypt
export const encryption = (array) => {
  return JSON.stringify(
    CryptoJS?.AES?.encrypt(JSON.stringify(array), key).toString()
  );
};

// Decrypt
export const decryption = (encryption) => {
  const bytes = encryption && CryptoJS?.AES?.decrypt(encryption, key);
  return encryption ? JSON.parse(bytes?.toString(CryptoJS.enc.Utf8)) : null;
};
// ========================encrypt-decrypt-close=========================

// leave type
export const leaveTypeListForEmail = [
  {
    value: 1,
    label: `Casual`,
  },
  {
    value: 2,
    label: `Anual`,
  },
  {
    value: 3,
    label: `Sick`,
  },
  {
    value: 4,
    label: `Menstrual`,
  },
  {
    value: 5,
    label: `Maternity`,
  },
  {
    value: 6,
    label: `Reimburse`,
  },
];

export const isImgUrl = async (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onerror = () => resolve(false);
    img.onload = () => resolve(true);
  });
};

export const altImg = async (imageLink) => {
  const isImgUrl = async (url) => {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    });
  };
  const res = await isImgUrl(imageLink);
  return res;
};

export const formatPhoneNumber = (phone) => {
  return phone.replace(/(\+88|\D)/g, "");
};

export const startsWithNumeric = (str) => {
  if (!str) return false;
  const regex = /^[0-9]+$/; // ^ denotes the start, [0-9]+ matches one or more numeric characters, $ denotes the end
  return regex.test(str);
};

export const removeSpecialCharacters = (inputString) => {
  if (!inputString) return "";
  // Use regular expression to remove special characters
  const cleanedString = inputString.replace(/[^\w]/g, "");

  if (!startsWithNumeric(cleanedString)) {
    return "";
  }

  if (cleanedString.length === 13 && cleanedString.startsWith("88")) {
    const formattedString = `(+88) ${cleanedString.slice(
      2,
      7
    )}-${cleanedString.slice(7, 10)}-${cleanedString.slice(10)}`;
    return formattedString;
  }

  if (cleanedString.length === 11) {
    // Format the string as (+88) xxxxx-xxx-xxx
    const formattedString = `(+88) ${cleanedString.slice(
      0,
      5
    )}-${cleanedString.slice(5, 8)}-${cleanedString.slice(8)}`;
    return formattedString;
  }

  // Format the string as +88 xxxxx-xxx-xxx
  const formattedString = `(+88) ${cleanedString.slice(
    0,
    5
  )}-${cleanedString.slice(5, 8)}-${cleanedString.slice(8)}`;
  return formattedString;
};

export const phoneNumberFormatter = (number) => {
  if (!number) return "";
  if (validatePhone(number)) {
    return number;
  }
  let formattedPhone = number;
  if (number.startsWith("01") && number.length == 11) {
    const replaced = number
      .slice(2, 11)
      .replace(/.{3}/g, function (value, index) {
        return value + (index % 40 == 36 ? "\n" : "-");
      });

    formattedPhone = `(+88) 01${replaced.slice(0, replaced.length - 1)}`;
  }
  if (number.startsWith("1") && number.length == 10) {
    const replaced = number
      .slice(1, 10)
      .replace(/.{3}(?!$)/g, function (value) {
        return value + "-";
      });

    formattedPhone = `(+88) 01${replaced}`;
  }
  return formattedPhone;
};

export const phoneNumberFormatterForSearch = (number) => {
  if (!number) {
    return number;
  }
  let formattedPhone;
  if (number.startsWith("01")) {
    const replaced = number
      .slice(2, number.length)
      .replace(/.{3}/g, function (value, index) {
        return value + (index % 40 == 36 ? "\n" : "-");
      });

    formattedPhone = `(+88) 01${replaced.slice(0, replaced.length - 1)}`;
  }
  return formattedPhone;
};
