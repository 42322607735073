import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import moment from "moment";
import { useState } from "react";
import { Table, Col } from "reactstrap";
import { useHistory } from "react-router";
import { Pagination } from "@mui/material";
import TableDataLoading from "../../../../utils/HelperComponent/TableDataLoading";
import { IsThereWeekend, usePagination } from "../../../../utils/helpers";
import { decryption } from "../../../HelperFunctions";
import NoticeDetailsModal from "./NoticeDetailsModal";
import { DELETE_NOTICE } from "../../../../GraphQL/Mutation";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";

const NoticeListTable = ({ loading, notices, deleteHandler, refetch }) => {
  const history = useHistory();
  const [userModal, setUserModal] = useState(false);
  const [noticeDetails, setNoticeDetails] = useState({});
  refetch();
  const userToggle = () => {
    setUserModal((prevState) => !prevState);
  };
  // material pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(notices?.length / PER_PAGE);
  const pageData = usePagination(notices, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };
  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );

  return (
    <Col>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th style={{ width: "60px" }}>ID</th>

            <th>Name</th>
            <th>Category</th>
            <th>Publish By</th>
            <th>Publish Date</th>
            {permissions?.includes("delete_holyday") ? <th>Action</th> : null}
            {permissions?.includes("delete_holyday") ? <th>Status</th> : null}
          </tr>
        </thead>
        <tbody className="table-tr-last-child">
          {loading ? (
            <TableDataLoading colSpan="8" />
          ) : pageData?.currentData()?.length ? (
            pageData?.currentData()?.map((item, index) => (
              <tr
                onClick={() => {
                  userToggle();
                  setNoticeDetails(item);
                }}
              >
                <td>{index + 1}</td>
                <td>
                  {item?.name.length > 50
                    ? `${item?.name.substring(0, 80)}...`
                    : item?.name}
                </td>
                <td>{item?.category}</td>
                <td>{item?.user?.name}</td>
                <td>
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(new Date(Number(item?.createdAt)))}
                </td>
                <td>
                  {permissions?.includes("delete_holyday") ? (
                    <button
                      className="inbox__email-btn"
                      type="button"
                      aria-label="edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteHandler(item?.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </button>
                  ) : null}
                  {permissions?.includes("update_holyday") ? (
                    <button
                      className="inbox__email-btn mr-1"
                      type="button"
                      aria-label="edit"
                      onClick={() =>
                        history.push({
                          pathname: "/notice/new",
                          state: {
                            notice: item,
                          },
                        })
                      }
                    >
                      <EditIcon fontSize="small" />
                    </button>
                  ) : null}
                </td>
                {permissions?.includes("delete_holyday") ? (
                  <td>
                    {item?.notice_status == "active" ? (
                      <div style={{ textAlign: "start" }}>
                        <CheckCircleIcon
                          fontSize="small"
                          sx={{ color: "green" }}
                        />
                      </div>
                    ) : (
                      <div style={{ textAlign: "start" }}>
                        <RemoveCircleIcon
                          fontSize="small"
                          sx={{ color: "red" }}
                        />
                      </div>
                    )}
                  </td>
                ) : null}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center text-success">
                <b>There is no Notice</b>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Pagination count={count} page={page} onChange={handleChange} />
      <NoticeDetailsModal
        toggle={userToggle}
        isOpen={userModal}
        notice={noticeDetails}
      />
    </Col>
  );
};

export default NoticeListTable;
