import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import { EmailsProps } from "@/shared/prop-types/EmailProps";
import EmailListItem from "./EmailListItem";
import EmailsControls from "./EmailsControls";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_DEPARTMENT,
  GET_ALL_LEAVES,
  GET_USER_BY_ID,
} from "../../../../../GraphQL/Queries";

import jwt from "jsonwebtoken";
import { Pagination } from "@mui/material";
import { usePagination } from "../../../../../utils/helpers";
import { decryption } from "../../../../HelperFunctions";

const InboxTable = ({ emails, onLetter }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [pageOfItems, setPageOfItems] = useState(null);
  const [emailsWithID, setEmailsWithID] = useState([]);
  const [searchByOfficeId, setSearchByOfficeId] = useState("");
  const [allLeaves, setAllLeaves] = useState([]);
  const LoadAllLeaves = useQuery(GET_ALL_LEAVES);
  const [filterText, setFilterText] = useState("");
  // it is user for 'allLeaves' state update
  const [anyChangeLeave, setAnyChangeLeave] = useState(false);
  const token = localStorage?.getItem("token");
  const DeptList = useQuery(GET_ALL_DEPARTMENT);
  // roles
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));

  // get login user information
  let userID;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    userID = decoded?.id;
  });

  // get user information
  const LoadUserInformation = useQuery(GET_USER_BY_ID, {
    skip: !userID,
    variables: {
      id: userID,
    },
  });

  // get department of head information
  const LoadHeadOfDepartments = useQuery(GET_ALL_DEPARTMENT);

  // sorted function
  Array.prototype.sortBy = function (p) {
    return this.slice(0).sort(function (a, b) {
      return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
    });
  };

  useEffect(() => {
    if (LoadAllLeaves?.data?.getAllLeaves) {
      // setAllLeaves(LoadAllLeaves?.data?.getAllLeaves);
      if (
        roles?.includes("System Admin") ||
        roles?.includes("Human Resource (HR)")
      ) {
        setAllLeaves(LoadAllLeaves?.data?.getAllLeaves?.sortBy("createdAt"));
        LoadAllLeaves.refetch();
      } else {
        let emp = [];
        DeptList?.data?.getAllDepartments
          ?.filter(
            (dept) => dept.user_id == LoadUserInformation?.data?.getUserById?.id
          )
          ?.map((dept) => {
            LoadAllLeaves?.data?.getAllLeaves
              ?.filter((item) => item?.user?.additionalInfo?.dept_id == dept.id)
              ?.map((employee) => emp.push(employee));
          });
        setAllLeaves(emp?.sortBy("createdAt"));
        LoadAllLeaves.refetch();
      }
    }
  }, [LoadAllLeaves?.data?.getAllLeaves, anyChangeLeave]);

  useEffect(() => {
    setEmailsWithID(emails.map((item, index) => ({ ...item, id: index })));
  }, [emails]);

  const onChangePage = (page) => {
    setPageOfItems(page);
  };

  const onChangeSelect = () => {
    setIsChecked(!isChecked);
  };
  const filterHander = (filterStatus) => {
    setFilterText(filterStatus);
  };
  const searchOnchangeHandler = (event) => {
    setSearchByOfficeId(event.target.value);
  };

  // material pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  let count;
  let pageData;

  if (filterText) {
    count = Math.ceil(
      allLeaves?.filter((item) => item?.leave_status == filterText)?.length /
        PER_PAGE
    );
    pageData = usePagination(
      allLeaves?.filter((item) => item?.leave_status == filterText),
      PER_PAGE
    );
  } else if (searchByOfficeId) {
    count = Math.ceil(
      allLeaves?.filter(
        (item) =>
          item?.user?.name
            ?.toLowerCase()
            .match(new RegExp(searchByOfficeId?.toLowerCase(), "g"))?.length /
          PER_PAGE
      )
    );
    pageData = usePagination(
      allLeaves?.filter((item) =>
        item?.user?.name
          ?.toLowerCase()
          .match(new RegExp(searchByOfficeId?.toLowerCase(), "g"))
      ),
      PER_PAGE
    );
  } else {
    count = Math.ceil(allLeaves?.length / PER_PAGE);
    pageData = usePagination(allLeaves, PER_PAGE);
  }

  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };

  return (
    <div className="inbox__content">
      <EmailsControls
        reloadHandler={LoadAllLeaves}
        emails={emails}
        filterHander={filterHander}
        onChangePage={onChangePage}
        onChangeSelect={onChangeSelect}
        pageOfItems={pageOfItems}
        searchOnchangeHandler={searchOnchangeHandler}
      />
      <Table className="table--bordered mb-3" responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Applicant name</th>
            <th>Start</th>
            <th>End</th>
            <th>Day</th>
            <th>Status</th>
            <th>Applied</th>
          </tr>
        </thead>

        <tbody>
          {pageData?.currentData()?.length > 0 ? null : (
            <tr>
              <td colSpan="7" className="text-center text-success">
                <b>There is no any leave application</b>
              </td>
            </tr>
          )}
          {
            // LoadHeadOfDepartments?.data?.getAllDepartments?.find(item => item?.id == LoadUserInformation?.data?.getUserById?.additionalInfo?.dept_id)?.user?.__typename == "HeadOfDeparmentType" &&
            filterText
              ? pageData
                  ?.currentData()
                  ?.sortBy("createdAt")
                  ?.map((item, index) => (
                    <EmailListItem
                      key={item.id}
                      email={item}
                      itemId={index}
                      onLetter={onLetter}
                      isChecked={isChecked}
                      setAnyChangeLeave={setAnyChangeLeave}
                      LoadAllLeaves={LoadAllLeaves}
                      allLeaves={allLeaves}
                    />
                  ))
              : searchByOfficeId
              ? pageData
                  ?.currentData()
                  ?.sortBy("createdAt")
                  ?.map((item, index) => (
                    <EmailListItem
                      key={item.id}
                      email={item}
                      itemId={index}
                      onLetter={onLetter}
                      isChecked={isChecked}
                      setAnyChangeLeave={setAnyChangeLeave}
                      LoadAllLeaves={LoadAllLeaves}
                      allLeaves={allLeaves}
                    />
                  ))
              : pageData
                  ?.currentData()
                  ?.sortBy("createdAt")
                  ?.map((item, index) => (
                    <EmailListItem
                      key={item.id}
                      email={item}
                      itemId={index}
                      onLetter={onLetter}
                      isChecked={isChecked}
                      setAnyChangeLeave={setAnyChangeLeave}
                      LoadAllLeaves={LoadAllLeaves}
                      allLeaves={allLeaves}
                    />
                  ))
          }
        </tbody>
      </Table>

      <Pagination count={count} page={page} onChange={handleChange} />
    </div>
  );
};

InboxTable.propTypes = {
  emails: EmailsProps.isRequired,
  onLetter: PropTypes.func.isRequired,
};

export default InboxTable;
