import withAuthFirebase from "@/shared/components/auth/withAuthFirebase";
import LogInFormPhoto from "@/shared/components/loginPhotoForm/LogInFormPhoto";
import LogInForm from "@/shared/components/loginForm/LogInForm";
import showResults from "@/utils/showResults";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ALL_DISTRICT,
  ALL_UNION,
  ALL_UPAZILLA,
  LOAD_DIVISION,
} from "../../../GraphQL/Queries";
import classnames from "classnames";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import CustomerLogin from "../CustomerLogin";
import CustomerLoginForm from "../CustomerLogin/CustomerLoginForm";
import NoticeLoginPage from "../../Company/components/Notice/NoticeLoginPage";

const LogIn = ({ changeIsOpenModalFireBase }) => {
  const allDivision = useQuery(LOAD_DIVISION);
  const allDistricts = useQuery(ALL_DISTRICT);
  const allUpazilla = useQuery(ALL_UPAZILLA);
  const allUnion = useQuery(ALL_UNION);
  const addressState = useSelector((state) => state);
  useEffect(() => {
    localStorage.setItem(
      "division",
      JSON.stringify(allDivision?.data?.getDivision)
    );
    localStorage.setItem(
      "district",
      JSON.stringify(allDistricts?.data?.getDistrict)
    );
    localStorage.setItem(
      "upazilla",
      JSON.stringify(allUpazilla?.data?.getUpazilla)
    );
    localStorage.setItem("union", JSON.stringify(allUnion?.data?.getUnion));
    allDivision?.refetch();
  }, [
    allDistricts?.data?.getDistrict?.length,
    allUpazilla?.data?.getUpazilla?.length,
    allUnion?.data?.getUnion?.length,
    allDivision?.data?.getDivision?.length,
    addressState,
  ]);

  const [activeTab, setActiveTab] = useState("2");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        {/* <LogInForm onSubmit={showResults} form="log_in_form" /> */}
        <Container>
          <Row className="tabs__wrap">
            <Col xs="12" md="6">
              <NoticeLoginPage />
            </Col>
            <Col xs="12" md="6">
              <Nav tabs>{/* Tab Navigation */}</Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <CustomerLoginForm
                    onSubmit={showResults}
                    form="log_in_form"
                  />
                </TabPane>
                <TabPane tabId="2">
                  <LogInForm onSubmit={showResults} form="log_in_form" />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
LogIn.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default withAuthFirebase(LogIn);
