import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button } from "@mui/material";
import Cookies from "js-cookie";

import ClearIcon from "@mui/icons-material/Clear";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Table,
  UncontrolledDropdown,
  Row,
} from "reactstrap";
import { GET_USERS } from "../../GraphQL/Queries";
import PropagateLoader from "react-spinners/PropagateLoader";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import AssignRoleEdit from "./AssignRoleEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { USER_DELETED_STATUS_UPDATE } from "../../GraphQL/Mutation";
import { useHistory } from "react-router";
import {
  decryption,
  formatPhoneNumber,
  removeSpecialCharacters,
} from "../HelperFunctions";
import jwt from "jsonwebtoken";
import { usePagination } from "../../utils/helpers";
import { Pagination } from "@mui/material";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
import { classPrivateMethod } from "@babel/types";
import UserDetailsModal from "../Account/Profile/components/UserDetailsModal";
import UserListSkeleton from "./UserListSkeleton";
import useLeaveDeductionsQuery from "../../GraphQL/hooks/queries/useLeaveDeductionsQuery";
import LateDeductionForm from "../Leave/components/LeaveDeduction/components/LateDeductionForm";
import BreadCrumb from "../../shared/components/BreadCrumb/BreadCrumb";
import SkeletonLoading from "../../shared/components/BreadCrumb/SkeletonLoading";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Highlighter from "react-highlight-words";
const UserList = () => {
  const history = useHistory();

  const location = useLocation(); // Access the location object
  // const { search } = queryString.parse(location.search); // Parse the search query from URL
  const { search, page, isActive } = queryString.parse(location.search);
  //
  const [searchValue, setSearchValue] = useState(search || "");
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const [selectedUserType, setSelectedUserType] = useState(isActive || "null");

  const updateURLParams = (params) => {
    const newParams = {
      search: searchValue,
      page: currentPage,
      isActive: selectedUserType,
      ...params,
    };
    const queryParams = queryString.stringify(newParams);
    history.push({ pathname: location.pathname, search: `?${queryParams}` });
  };

  // Decoding the search parameter
  const decodedSearch = search ? decodeURIComponent(search) : null;

  // try code end
  const token = localStorage?.getItem("token");
  let userID;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    userID = decoded?.id;
  });

  //  late login features start
  const [leaveDeduction, setLeaveDeduction] = useState({
    leaveDeductionModal: false,
    leaveDeductions: null,
    page: 1,
    size: 10,
  });

  //   ** leave deductions query
  const LoadLeaveDeductionData = useLeaveDeductionsQuery({
    variables: {
      input: {
        page: leaveDeduction?.page,
        size: leaveDeduction?.size,
      },
    },
  });

  //   ** leave deduction modal
  const lateDeductionToggle = () => {
    setLeaveDeduction((prevState) => ({
      ...prevState,
      leaveDeductionModal: !prevState?.leaveDeductionModal,
    }));
  };

  //   ** set leave durations
  useEffect(() => {
    setLeaveDeduction((prevState) => ({
      ...prevState,
      leaveDeductions: LoadLeaveDeductionData,
    }));
  }, [LoadLeaveDeductionData?.data]);
  // late login features end
  // user image and name get from database

  const [modal, setModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [userData, setUserData] = useState({});

  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  const userToggle = () => {
    setUserModal((prevState) => !prevState);
  };
  const editRoles = (data) => {
    setUserData(data);
  };

  useEffect(() => {
    // Retrieve the last viewed page from session storage on component mount
    const savedPage = sessionStorage.getItem("currentPage");
    if (savedPage) {
      setCurrentPage(Number(savedPage)); // Convert to number
    }
    UserRefetch({ page: currentPage });
  }, []);

  const handleChange = (e, p) => {
    setCurrentPage(p);
    sessionStorage.setItem("currentPage", p);
    updateURLParams({ page: p });
    UserRefetch({
      page: p,
    });
  };

  const [deleteToggle, setDeleteToggle] = useState(false);
  // user delete function
  const [deleteUser] = useMutation(USER_DELETED_STATUS_UPDATE);
  const userDeleteHandler = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteUser({
          variables: {
            id: Number(userId),
          },
        });

        setDeleteToggle((prevState) => !prevState);
        if (result?.data?.userDeletedStatusUpdate?.result_code == "000") {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          UserRefetch();
        }
        if (result?.data?.userDeletedStatusUpdate?.result_code == "002") {
          Swal.fire(result?.data?.userDeletedStatusUpdate?.status, "", "error");
        }
      }
    });
  };

  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );

  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));
  const defaultPlaceholder =
    "Search by Name, Email, Phone Number, Department, Designation...";
  // Effect to set searchValue from cookies on component mount

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    if (data?.getUsers.pagination.currentPage) {
      setCurrentPage(data.getUsers.pagination.currentPage);
    } else {
      setCurrentPage(1);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    const searchValue = event.target.search.value.trim();

    // Check if the search value looks like a phone number pattern
    const phonePattern = /^[\d\-\(\)\+\s]+$/;
    const formattedSearchValue = phonePattern.test(searchValue)
      ? formatPhoneNumber(searchValue)
      : searchValue.trim();
    setSearchValue(formattedSearchValue);
    Cookies.set("search", formattedSearchValue);
    updateURLParams({ search: formattedSearchValue });
    UserRefetch({
      search: formattedSearchValue,
      page: 1,
      isActive: null,
    });
  };

  // useEffect(() => {
  //   if (searchValue === "") {
  //     UserRefetch({
  //       search: "",
  //       page: 1,
  //       isActive: null,
  //     });
  //   }
  // }, [searchValue]);

  const getPlaceholder = () => {
    return searchValue ? searchValue : defaultPlaceholder;
  };

  const handleFilterReset = () => {
    setSelectedUserType("null");
    setSearchValue("");
    history.replace({ search: "" });
    Cookies.set("search", "");
    UserRefetch({
      search: "",
      page: 1,
      isActive: null,
    });
    setCurrentPage(1);
  };

  const {
    data,
    loading,
    error,
    refetch: UserRefetch,
  } = useQuery(GET_USERS, {
    variables: { page: 1, size: 10, isActive: null, search: "" },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  // const [currentPage, setCurrentPage] = useState(
  //   data?.getUsers.pagination.currentPage || 1
  // );
  useEffect(() => {
    if (decodedSearch) {
      UserRefetch({
        page: 1,
        size: 10,
        isActive: null,
        search: decodedSearch,
      });
    }
  }, [decodedSearch, UserRefetch]);

  const handleUserTypeChange = (event) => {
    const userTypeString = event.target.value;

    // Determine if the value is "true", "false", or reset to fetch all users (null)
    let userType = null;
    if (userTypeString === "null") {
      userType = null;
    } else if (userTypeString === "true") {
      userType = true;
    } else if (userTypeString === "false") {
      userType = false;
    }

    updateURLParams({ isActive: userType, search: "", page: 1 });

    // Update the selected user type
    setSelectedUserType(userType);
    // Fetch users: If userType is null, fetch all users; otherwise filter by isActive

    UserRefetch({ isActive: userType, search: "", page: 1 });
  };

  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "User Lists",
    },
  ];
  const handleTextHighLight = (displayText, highlightText) => {
    if (!highlightText) {
      return displayText;
    }
    return (
      <Highlighter
        highlightClassName="text-white bg-success"
        searchWords={highlightText?.split(/\s/)?.filter((word) => word)}
        autoEscape={true}
        textToHighlight={displayText ?? ""}
      />
    );
  };

  return loading ? (
    <SkeletonLoading />
  ) : (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col xs={5} md={6}>
              <h3 className="page-title">User Lists</h3>
            </Col>
            <Col xs={7} md={6}>
              {permissions?.includes("create_employee") && (
                <button
                  className="btn btn-success float-right"
                  onClick={() => history.push("/user/create")}
                >
                  {" "}
                  + User Create
                </button>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={3} xs={12}>
              <div
                className="inbox__emails-controls-right w-100"
                style={{ position: "initial", display: "initial" }}
              >
                <form
                  className="inbox__emails-control-search mr-0"
                  onSubmit={handleSearchSubmit}
                >
                  <input
                    className="w-100"
                    value={searchValue}
                    name="search"
                    onChange={handleSearchChange}
                    placeholder={getPlaceholder()}
                  />
                  <button
                    type="submit"
                    className="inbox__emails-control-search-icon"
                    disabled={!searchValue}
                  >
                    <MagnifyIcon />
                  </button>
                  {searchValue && (
                    <Button
                      type="button"
                      onClick={handleFilterReset}
                      className="reset-filters-button "
                      size="small"
                      startIcon={<ClearIcon />} // Adjust icon size here
                      sx={{
                        minWidth: "unset",
                        fontSize: "8px",
                        marginLeft: "5px", //
                      }}
                    ></Button>
                  )}

                  {/* <button
                  // onClick={() => {
                  //   onClearSearch();
                  // }}
                  >
                    reset
                  </button> */}
                </form>
              </div>
            </Col>
            <Col md={3} xs={12}>
              <div className="d-flex">
                <select
                  className="form-select"
                  style={{
                    backgroundColor: "#d8dfe9",
                    padding: "6px 9px",
                    color: "#787985",
                    border: "2px solid transparent",
                    borderRadius: "4px",
                    backgroundClip: "padding-box",
                    transition: "all 0.2s ease-in-out",
                  }}
                  value={selectedUserType}
                  onChange={handleUserTypeChange}
                >
                  <option value="null">All Users</option>
                  <option value="true">Active Users</option>
                  <option value="false">Inactive Users</option>
                </select>
                <Button
                  type="button"
                  onClick={handleFilterReset}
                  className="reset-filters-button "
                  size="small"
                  startIcon={<ClearIcon />} // Adjust icon size here
                  sx={{
                    minWidth: "unset",
                    fontSize: "8px",
                    marginLeft: "5px", //
                  }}
                ></Button>
              </div>
            </Col>
          </Row>
          {roles?.includes("System Admin") ||
          roles?.includes("System Manager") ? (
            <Table role="table" striped hover responsive>
              <thead>
                <tr role="row">
                  <th>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th>Department</th>
                  <th>Joined</th>
                  <th>Office Id</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Action</th>
                  <th>Employee Status</th>
                </tr>
              </thead>
              <tbody className="table-tr-last-child">
                {data?.getUsers?.data?.length > 0 ? (
                  data.getUsers.data.map((item, index) => (
                    <tr key={index}>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {(data.getUsers.pagination.currentPage - 1) * 10 +
                          (index + 1)}
                      </td>
                      <td
                        role="cell"
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        <div className="employee-list__img-wrap">
                          <img
                            src={
                              item?.images ||
                              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                            }
                            alt="image"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png";
                            }}
                          />
                        </div>
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {handleTextHighLight(item?.name, searchValue)} (
                        {item?.rfid})
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {handleTextHighLight(item?.designation, searchValue)}
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {handleTextHighLight(item?.department, searchValue)}
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {moment(item?.joined_date).fromNow("yy")}
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {item?.office_id}
                      </td>
                      <td
                      // onClick={() => {
                      //   userToggle();
                      //   setUserDetails(item);
                      // }}
                      >
                        {handleTextHighLight(
                          item?.phone,
                          searchValue
                            ? removeSpecialCharacters(searchValue)
                            : ""
                        )}
                      </td>
                      <td
                      // onClick={() => {
                      //   userToggle();
                      //   setUserDetails(item);
                      // }}
                      >
                        {handleTextHighLight(item?.email, searchValue)}
                      </td>

                      <td>
                        {/* dropdown action */}
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="icon icon--center"
                            outline
                            size="sm"
                          >
                            <MoreVertIcon />
                          </DropdownToggle>

                          {/* Use a unique key to force re-render */}
                          <DropdownMenu
                            className="dropdown__menu"
                            key={item?.id}
                          >
                            {userID === item?.id ? null : (
                              <DropdownItem>
                                {permissions?.includes("assign_role") && (
                                  <button
                                    className="inbox__email-btn w-100"
                                    onClick={() => {
                                      toggle();
                                      editRoles(item);
                                    }}
                                  >
                                    <SettingsIcon fontSize="small" /> Role
                                    Assign
                                  </button>
                                )}
                              </DropdownItem>
                            )}

                            {userID === item?.id ? null : (
                              <DropdownItem>
                                {permissions?.includes("delete_employee") && (
                                  <button
                                    className="inbox__email-btn w-100"
                                    type="button"
                                    aria-label="edit"
                                    onClick={() => {
                                      userDeleteHandler(item?.id);
                                      // setDeleteUserId(item?.id);
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" /> Delete
                                  </button>
                                )}
                              </DropdownItem>
                            )}

                            <DropdownItem>
                              {permissions?.includes("update_employee") && (
                                <button
                                  className="inbox__email-btn w-100"
                                  type="button"
                                  aria-label="edit"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/user/create",
                                      state: {
                                        userInfo: item,
                                        userId: item?.id,
                                      },
                                    })
                                  }
                                >
                                  <EditIcon fontSize="small" /> Update
                                </button>
                              )}
                            </DropdownItem>

                            {/* Late login feature */}
                            {userID === item?.id ? null : (
                              <DropdownItem>
                                <div className="float-right">
                                  {permissions?.includes(
                                    "create_leave_deduction"
                                  ) && (
                                    <button
                                      onClick={() => {
                                        lateDeductionToggle();
                                        setUserDetails(item);
                                      }}
                                      className="inbox__email-btn w-100"
                                      type="button"
                                      aria-label="edit"
                                    >
                                      Late logins
                                    </button>
                                  )}
                                </div>
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                      <td>
                        {item?.is_active ? (
                          <div style={{ textAlign: "center" }}>
                            <CheckCircleIcon
                              fontSize="small"
                              sx={{ color: "green" }}
                            />
                          </div>
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            <RemoveCircleIcon
                              fontSize="small"
                              sx={{ color: "red" }}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={12}
                      className="text-center text-danger font-weight-bold"
                    >
                      User not found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <Table role="table" striped hover responsive>
              <thead>
                <tr role="row">
                  <th>#</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th>Department</th>
                  <th>Joined</th>
                  <th>Office Id</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="10">
                      <PropagateLoader
                        color={color}
                        css={override}
                        loading={true}
                        size={10}
                      />
                    </td>
                  </tr>
                ) : data?.getUsers.data?.length ? (
                  data?.getUsers.data?.map((item, index) => (
                    <tr key={item?.id}>
                      <th
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {index + 1}
                      </th>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                        role="cell"
                      >
                        <div className="employee-list__img-wrap">
                          <img
                            src={
                              item?.images
                                ? item?.images
                                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                            }
                            alt="image"
                          />
                        </div>
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {item?.name} ({item?.username})
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {item?.additionalInfo?.designationInfo?.name}
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {item?.additionalInfo?.departmentInfo?.name}
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {moment(item?.additionalInfo?.joining_date).fromNow(
                          "yy"
                        )}
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {item?.additionalInfo?.office_id}
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {item?.phone}
                      </td>
                      <td
                        onClick={() => {
                          userToggle();
                          setUserDetails(item);
                        }}
                      >
                        {item?.email}
                      </td>
                      <td>
                        {/* dropdown action */}
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="icon icon--center"
                            outline
                            size="sm"
                          >
                            <MoreVertIcon />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown__menu">
                            {userID == item?.id ? null : (
                              <DropdownItem>
                                {permissions?.includes("assign_role") && (
                                  <button
                                    className="inbox__email-btn w-100"
                                    onClick={() => {
                                      toggle();
                                      editRoles(item);
                                    }}
                                  >
                                    <SettingsIcon fontSize="small" /> Role
                                    Assign
                                  </button>
                                )}
                              </DropdownItem>
                            )}
                            {userID == item?.id ? null : (
                              <DropdownItem>
                                {permissions?.includes("delete_employee") && (
                                  <button
                                    className="inbox__email-btn w-100"
                                    type="button"
                                    aria-label="edit"
                                    onClick={() => {
                                      userDeleteHandler(item?.id);
                                      setDeleteUserId(item?.id);
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" /> Delete
                                  </button>
                                )}
                              </DropdownItem>
                            )}
                            <DropdownItem>
                              {permissions?.includes("update_employee") && (
                                <button
                                  className="inbox__email-btn w-100"
                                  type="button"
                                  aria-label="edit"
                                  onClick={() =>
                                    history.push({
                                      pathname: "/user/create",
                                      state: {
                                        userInfo: item,
                                      },
                                    })
                                  }
                                >
                                  <EditIcon fontSize="small" /> Update
                                </button>
                              )}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))
                ) : (
                  "User not found"
                )}
              </tbody>
            </Table>
          )}

          {
            <Pagination
              count={data?.getUsers.pagination.lastPage}
              page={currentPage}
              onChange={handleChange}
            />
          }

          <Modal isOpen={modal} toggle={toggle} animation={true} centered>
            <AssignRoleEdit
              user={userData}
              toggle={toggle}
              refetch={UserRefetch}
            />
          </Modal>
          <UserDetailsModal
            toggle={userToggle}
            isOpen={userModal}
            userId={userDetails.id}
          />
          {leaveDeduction?.leaveDeductionModal && (
            <LateDeductionForm
              userDetails={userDetails}
              modal={leaveDeduction?.leaveDeductionModal}
              toggle={lateDeductionToggle}
              leaveDeduction={leaveDeduction}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserList;
