import React from "react";
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import { SelectField } from "@/shared/components/form/Select";
import { useForm } from "react-hook-form";
import FormField from "../FormField";
import { useHistory } from "react-router";
import { GET_ALL_USER } from "../../../../GraphQL/Queries";
import { useQuery } from "@apollo/client";

const NewDeptMember = () => {
  const history = useHistory();
  const UserList = useQuery(GET_ALL_USER);
  const { handleSubmit, control } = useForm();
  const onSubmit = (value) => {};
  return (
    <Col md={12}>
      <Col md={12}>
        <h3 className="page-title">New Department Member</h3>
      </Col>
      <Card>
        <CardBody>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Col md={6} className="mx-auto">
              <Row>
                <Col md={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Name</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="user_id"
                        control={control}
                        component={SelectField}
                        options={UserList?.data?.getUser?.map((user) => ({
                          value: String(user.id),
                          label: user.name,
                        }))}
                        style={{ textAlign: "center", textAlignLast: "center" }}
                        placeholder="Select user"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Department</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="department_name"
                        control={control}
                        component={SelectField}
                        options={[
                          {
                            value: "Software",
                            label: "Software",
                          },
                          {
                            value: "Hardware",
                            label: "Hardware",
                          },
                        ]}
                        placeholder="Department"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">
                      Designation
                    </label>
                    <div className="form__form-group-field">
                      <FormField
                        name="designation"
                        component="input"
                        control={control}
                        rules={{ required: true }}
                        type="text"
                        placeholder={`Enter designation`}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">Office ID</label>
                    <div className="form__form-group-field">
                      <FormField
                        name="office_id"
                        component="input"
                        control={control}
                        rules={{ required: true }}
                        type="text"
                        placeholder={`Enter office id`}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">
                      Description
                    </label>
                    <div className="form__form-group-field">
                      <FormField
                        name="description"
                        component="textarea"
                        control={control}
                        rules={{ required: true }}
                        type="text"
                        placeholder={`Enter description`}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        type="button"
                        onClick={() => history.push("/department/member")}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                      <Button color="primary" type="submit">
                        Create
                      </Button>
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default NewDeptMember;
