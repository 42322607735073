import { onlyTime } from "../../../../../utils/helpers";

const AttendanceReportWorkingTime = ({ data, leaveDeduct, lateLogins }) => {
  const leave = leaveDeduct;

  const lateLoginsdays = lateLogins;
  console.log(
    "🚀 ~ AttendanceReportWorkingTime ~ lateLoginsdays:",
    lateLoginsdays
  );

  console.log("leavededuct days:", leave);
  return (
    <div>
      <h4 className="mb-2">Working Hour Summary</h4>
      <div>
        <p className="row">
          <div
            style={{
              color: "#999999",
              fontWeight: 500,
            }}
            className="col-auto p-0 pb-1"
          >
            Total Work Hour:
          </div>
          <div className="col p-0 pl-2 pb-1">
            {data?.total_work_hours} Hours
          </div>
        </p>
      </div>

      <div>
        <p className="row">
          <div
            style={{
              color: "#999999",
              fontWeight: 500,
            }}
            className="col-auto p-0 pb-1"
          >
            Work Hour (reported):
          </div>
          <div className="col p-0 pl-2 pb-1">
            {onlyTime(
              data?.working_hour_report?.hours,
              data?.working_hour_report?.minutes,
              data?.working_hour_report?.seconds
            )}
          </div>
        </p>
      </div>

      <div>
        <p className="row">
          <div
            style={{
              color: "#999999",
              fontWeight: 500,
            }}
            className="col-auto p-0 pb-1"
          >
            Overtime Work Hour:
          </div>
          <div className="col p-0 pl-2 pb-1">
            {onlyTime(
              data?.overtime?.hours,
              data?.overtime?.minutes,
              data?.overtime?.seconds
            )}
          </div>
        </p>
      </div>
      <div>
        <p className="row">
          <div
            style={{
              color: "#999999",
              fontWeight: 500,
            }}
            className="col-auto p-0 pb-1"
          >
            % of Reported Work Hour:
          </div>
          <div className="col p-0 pl-2 pb-1">
            {data?.working_hour_report_percentage} %
          </div>
        </p>
      </div>
      {/* leave deduct */}
      <div>
        <p className="row">
          <div
            style={{
              color: "#999999",
              fontWeight: 500,
            }}
            className="col-auto p-0 pb-1"
          >
            Late Login Leave Deduct:
          </div>
          <div className="col p-0 pl-2 pb-1">{leave}</div>
        </p>
      </div>
      {/* leave deduct end */}
      <div>
        <p className="row">
          <div
            style={{
              color: "#999999",
              fontWeight: 500,
            }}
            className="col-auto p-0 pb-1"
          >
            Late Login :
          </div>
          <div className="col p-0 pl-2 pb-1">{lateLoginsdays} Days</div>
        </p>
      </div>
    </div>
  );
};

export default AttendanceReportWorkingTime;
