import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import CheckIcon from "mdi-react/CheckIcon";
import MenuDownIcon from "mdi-react/MenuDownIcon";
import ReloadIcon from "mdi-react/ReloadIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import PaginationComponent from "@/shared/components/pagination/PaginationComponent";
import { EmailsProps } from "@/shared/prop-types/EmailProps";

const EmailsControls = ({
  emails,
  onChangePage,
  onChangeSelect,
  filterHander,
  reloadHandler,
  searchOnchangeHandler,
}) => (
  <div className="inbox__emails-controls-wrap">
    <div className="inbox__emails-controls pl-0">
      <UncontrolledDropdown>
        <DropdownToggle className="icon icon--right" outline size="sm">
          <p>
            Filter By <MenuDownIcon />
          </p>
        </DropdownToggle>
        <DropdownMenu className="dropdown__menu">
          <DropdownItem onClick={() => filterHander("")}>All</DropdownItem>
          <DropdownItem onClick={() => filterHander("Pending")}>
            Pending
          </DropdownItem>
          <DropdownItem onClick={() => filterHander("Approved")}>
            Approved
          </DropdownItem>
          <DropdownItem onClick={() => filterHander("Denied")}>
            Denied
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {/* <Button onClick={()=> reloadHandler.refetch()} outline className="icon inbox__emails-control-reload" size="sm" onClick={e => e.preventDefault()}>
        <ReloadIcon />
      </Button> */}
    </div>
    <div className="inbox__emails-controls-right">
      <div className="inbox__emails-control-search mr-0">
        <input
          style={{ width: "200px" }}
          onChange={() => searchOnchangeHandler(event)}
          placeholder="Search by name"
        />
        <div className="inbox__emails-control-search-icon">
          <MagnifyIcon />
        </div>
      </div>
    </div>
  </div>
);

EmailsControls.propTypes = {
  emails: EmailsProps.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
};

export default EmailsControls;
