import React from "react";
import { Card, Col, CardBody } from "reactstrap";
import Inbox from "./Inbox";
import emails from "./email-list";
import BreadCrumb from "../../../../../shared/components/BreadCrumb/BreadCrumb";
const breadCrumbData = [
  {
    name: "Dashboard",
    link: "/",
  },
  {
    name: "Leave Request ",
  },
];
const InboxCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <Col>
          <BreadCrumb breadCrumbData={breadCrumbData} />
        </Col>

        <h3 className="page-title">Leave application list</h3>
        <Inbox emails={emails} />
      </CardBody>
    </Card>
  </Col>
);

export default InboxCard;
