// import { logout as logoutAuth0 } from "@/shared/components/auth/withAuth0";
import { logout as logoutAuth0 } from "../../../../shared/components/auth/withAuth0";
// import { UserProps } from "@/shared/prop-types/ReducerProps";
import { UserProps } from "../../../../shared/prop-types/ReducerProps";
import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import jwt from "jsonwebtoken";
import DownIcon from "mdi-react/ChevronDownIcon";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import { LOGOUT_ATTENDANCE } from "../../../../GraphQL/Mutation";
import {
  GET_ALL_ADDITIONAL_INFO_BY_USER_ID,
  GET_USER_BY_ID,
} from "../../../../GraphQL/Queries";
import TopbarMenuLink from "./TopbarMenuLink";
import moment from "moment";
import { useHistory } from "react-router";
import { loginInfo } from "../../../../utils/helpers";

const Ava = `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`;

const TopbarProfile = ({ user }) => {
  const history = useHistory();
  const token = localStorage?.getItem("token");
  let userID;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    userID = decoded?.id;
  });
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [logoutAttendance] = useMutation(LOGOUT_ATTENDANCE);
  // login user information
  const loginUserInfo = useQuery(GET_ALL_ADDITIONAL_INFO_BY_USER_ID, {
    skip: !userID,
    variables: { user_id: userID },
  });

  const logout = async () => {
    // let returnValue;
    // await axios.get('https://www.cloudflare.com/cdn-cgi/trace').then(function (response) {
    //   returnValue = response?.data.trim().split('\n').reduce(function (obj, pair) {
    //     pair = pair.split('=');
    //     return obj[pair[0]] = pair[1], obj;
    //   }, {});
    // })

    // await logoutAttendance({
    //   variables: {
    //     emp_id: loginUserInfo?.data?.getAllAdditionalInfoByUserId?.rfid,
    //     time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    //     user_ip: returnValue?.ip
    //   }
    // })
    localStorage.removeItem("division");
    localStorage.removeItem("upazilla");
    localStorage.removeItem("district");
    localStorage.removeItem("union");
    localStorage.removeItem("token");
    localStorage.removeItem("address");
    localStorage.removeItem("permissions");
    localStorage.removeItem("roles");
    logoutAuth0();
    history.push("/log_in");
  };

  // user image and name get from database

  const { data, loading, error, refetch } = useQuery(GET_USER_BY_ID, {
    skip: !userID,
    variables: {
      id: userID,
    },
  });
  const [updateUser, setUpdateUser] = useState({});
  useEffect(() => {
    setUpdateUser(data?.getUserById);
    refetch();
  }, [userID, data?.getUserById]);

  const loggedInfo = loginInfo();
  console.log(updateUser);

  const handleNavigation = (path) => {
    history.push({
      pathname: path,
      state: {
        user: loggedInfo,
      },
    });
    toggleProfile(); // Call the function after navigation
  };

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img
          className="topbar__avatar-img"
          src={updateUser?.images ? updateUser?.images : Ava}
          alt="avatar"
        />

        <p className="topbar__avatar-name">
          {updateUser?.name ? updateUser?.name : null}{" "}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        {/* <div className="topbar__menu">
          <TopbarMenuLink
            title="My Profile"
            icon="user"
            path="/profile/info"
            onClick={() => {
              history.push({
                state: {
                  user: loggedInfo,
                },
                pathname: "/profile/info",
              });
              toggleProfile();
            }}
          />
          <div className="topbar__menu-divider" />
         
          <span className="topbar__link" onClick={logout}>
            <span className="topbar__link-icon lnr lnr-exit"></span>
            <p className="topbar__link-title">Log Out</p>
          </span>
        </div> */}

        <div className="topbar__menu">
          {/* Profile Link */}
          <span
            className="topbar__link"
            onClick={() => handleNavigation("/profile/info")}
          >
            <span className="topbar__link-icon lnr lnr-user"></span>
            <p className="topbar__link-title">My Profile</p>
          </span>
          <div className="topbar__menu-divider" />

          {/* Log Out Link */}
          <span className="topbar__link" onClick={logout}>
            <span className="topbar__link-icon lnr lnr-exit"></span>
            <p className="topbar__link-title">Log Out</p>
          </span>
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  user: UserProps.isRequired,
};

export default connect((state) => ({
  user: state.user,
}))(TopbarProfile);
