// import React from "react";
// import { CircularProgress, Box } from "@mui/material";

// const LoadingSpinner = () => {
//   return (
//     <Box
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       minHeight="100vh"
//     >
//       <CircularProgress />
//     </Box>
//   );
// };

// export default LoadingSpinner;
import React from "react";
import { Skeleton, Box, Grid } from "@mui/material";
import { Modal, Table } from "reactstrap";
const SkeletonLoader = () => {
  return (
    <Box padding={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Skeleton variant="circular" width={150} height={150} />
          <Skeleton variant="text" width={120} height={30} sx={{ mt: 3 }} />
          <Skeleton variant="text" width={150} height={20} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Skeleton variant="text" width="80%" height={30} sx={{ mb: 2 }} />
          <Table borderless hover responsive size="sm" striped>
            <tbody>
              {[...Array(8)].map((_, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton variant="text" width={80} height={20} />
                  </td>
                  <td>
                    <Skeleton variant="text" width="100%" height={20} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <h4 className="border-bottom mb-3 mt-4 pb-2">Address</h4>
          <Table borderless hover responsive size="sm" striped>
            <tbody>
              {[...Array(5)].map((_, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton variant="text" width={80} height={20} />
                  </td>
                  <td>
                    <Skeleton variant="text" width="100%" height={20} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SkeletonLoader;
