import { Card, CardBody, Col, Table, Button, Container, Row } from "reactstrap";
import TabPlusIcon from "mdi-react/TabPlusIcon";

import { useHistory } from "react-router";
import { decryption } from "../../../HelperFunctions";
import ClearIcon from "@mui/icons-material/Clear";
import BreadCrumb from "../../../../shared/components/BreadCrumb/BreadCrumb";
import NoticeListTable from "./NoticeListTable";
import { GET_ACTIVE_NOTICE, GET_NOTICE } from "../../../../GraphQL/Queries";
import { useMutation, useQuery } from "@apollo/client";
import Swal from "sweetalert2";
import { DELETE_NOTICE } from "../../../../GraphQL/Mutation";
import { useEffect, useState } from "react";
import MagnifyIcon from "mdi-react/MagnifyIcon";
const Notice = () => {
  const history = useHistory();
  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );
  const query = permissions?.includes("create_company")
    ? GET_NOTICE
    : GET_ACTIVE_NOTICE;
  const { data, loading, refetch } = useQuery(query);

  const [deleteNotice] = useMutation(DELETE_NOTICE, {
    onCompleted: (data) => {
      if (data.deleteNotice.result_code === "000") {
        Swal.fire({
          icon: "success",
          title: "Deleted",
          text: "The notice has been deleted successfully!",
        });
        refetch(); // Refresh the notice list after deletion
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.deleteNotice.status,
        });
      }
    },
    onError: (error) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "System error. Please contact the system admin.",
      });
      console.error("Error deleting notice:", error);
    },
  });

  const deleteHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNotice({ variables: { id } });
      }
    });
  };
  const [searchTerm, setSearchTerm] = useState("");

  const searchOnchangeHandler = (event) => {
    setSearchTerm(event.target.value);
  };
  let filteredNotices = null;
  // Filter notices based on the search term
  if (permissions?.includes("create_company")) {
    filteredNotices = data?.getNotice?.filter((notice) =>
      notice.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  } else {
    filteredNotices = data?.getActiveNotice?.filter((notice) =>
      notice.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  const [selectedUserType, setSelectedUserType] = useState("null");
  const handleUserTypeChange = (event) => {};
  const handleFilterReset = () => {};

  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "Notice List ",
    },
  ];
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3 className="page-title">Notice list</h3>
            </Col>
            <Col md={6}>
              {permissions?.includes("create_company") && (
                <Button
                  onClick={() => history.push("/notice/new")}
                  className="float-right"
                  color="primary"
                >
                  <TabPlusIcon />
                  {/* <Link to="/make/request">Make a request</Link> */}
                  Create Notice
                </Button>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={3} xs={12}>
              <div className="inbox__emails-control-search mr-0">
                <input
                  className="w-100"
                  onChange={searchOnchangeHandler}
                  placeholder="Search by Name"
                  value={searchTerm}
                />
                <div className="inbox__emails-control-search-icon">
                  <MagnifyIcon />
                </div>
              </div>
            </Col>
          </Row>
          {/* try code */}
          {/* <Col md={3} xs={12}>
            <div className="d-flex">
              <select
                className="form-select"
                style={{
                  backgroundColor: "#d8dfe9",
                  padding: "6px 9px",
                  color: "#787985",
                  border: "2px solid transparent",
                  borderRadius: "4px",
                  backgroundClip: "padding-box",
                  transition: "all 0.2s ease-in-out",
                }}
                value={selectedUserType}
                onChange={handleUserTypeChange}
              >
                <option value="null">All Users</option>
                <option value="true">Active Users</option>
                <option value="false">Inactive Users</option>
              </select>
              <Button
                type="button"
                onClick={handleFilterReset}
                className="reset-filters-button "
                size="small"
                startIcon={<ClearIcon />} // Adjust icon size here
                sx={{
                  minWidth: "unset",
                  fontSize: "8px",
                  marginLeft: "5px", //
                }}
              ></Button>
            </div>
          </Col> */}

          {/* try code end */}
          <Row>
            {permissions?.includes("create_company") ? (
              <NoticeListTable
                loading={loading}
                notices={filteredNotices ? filteredNotices : data?.getNotice}
                deleteHandler={deleteHandler}
                refetch={refetch}
              ></NoticeListTable>
            ) : (
              <NoticeListTable
                loading={loading}
                notices={
                  filteredNotices ? filteredNotices : data?.getActiveNotice
                }
                deleteHandler={deleteHandler}
                refetch={refetch}
              ></NoticeListTable>
            )}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Notice;
