import { Card, CardBody, Col, Table, Button, Container, Row } from "reactstrap";
import TabPlusIcon from "mdi-react/TabPlusIcon";
import HistoryTable from "./HistoryTable";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_LEAVES } from "../../../GraphQL/Queries";
import { useEffect, useState } from "react";
import { decryption } from "../../HelperFunctions";
import BreadCrumb from "../../../shared/components/BreadCrumb/BreadCrumb";

const History = () => {
  const history = useHistory();
  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );
  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "History",
    },
  ];
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3 className="page-title">Leave list</h3>
            </Col>
            <Col md={6}>
              <div className="float-right">
                {permissions?.includes("create_leave") && (
                  <Button
                    onClick={() => history.push("/leave/new")}
                    className=""
                    color="primary"
                  >
                    <TabPlusIcon />
                    {/* <Link to="/make/request">Make a request</Link> */}
                    Apply for leave
                  </Button>
                )}

                {permissions?.includes("create_wfh_od") && (
                  <Button
                    onClick={() => history.push("/leave/wfh-od")}
                    className=""
                    color="primary"
                  >
                    <TabPlusIcon />
                    {/* <Link to="/make/request">Make a request</Link> */}
                    Apply for WFH/OD
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <HistoryTable />
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default History;
