import React from "react";
import { useHistory } from "react-router";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import SkeletonLoading from "./SkeletonLoading";

const BreadCrumb = ({ breadCrumbData }) => {
  const history = useHistory();

  const handleClick = (link) => {
    // Navigate to the link using useHistory
    history.push(link);
    // Force full page refresh
  };
  const totalBreadcrumbs = breadCrumbData.length - 1;
  return (
    <Breadcrumb>
      {/* <SkeletonLoading loading={loading}> */}
      {breadCrumbData?.map((data, index) => {
        return (
          <BreadcrumbItem
            active={totalBreadcrumbs === index}
            aria-disabled={totalBreadcrumbs === index}
            key={index}
            onClick={() =>
              totalBreadcrumbs !== index ? handleClick(data.link) : () => {}
            }
            style={{ cursor: "pointer" }}
          >
            {data.name}
          </BreadcrumbItem>
        );
      })}
      {/* </SkeletonLoading> */}
    </Breadcrumb>
  );
};

export default BreadCrumb;
