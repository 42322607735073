export const fertilizer = [
  { label: "Organice fertilizer" },
  { label: "Urea " },
  { label: "TSP  " },
  { label: "MoP  " },
  { label: "DAP " },
  { label: "Zinc " },
  { label: "Boron " },
  { label: "Sulpher " },
];

export const weeds = [
  {
    label: "By mechanical weeding (tillage, mowing and/or manual).",
    value: 1,
  },
  { label: "Manual weeding by labor", value: 2 },
  { label: "By chemical herbicides, specify what do you use", value: 3 },
];

export const productSell = [
  { label: "Wholesale", value: 1 },
  { label: "Retailers", value: 2 },
  { label: "Farmers markets ", value: 3 },
  { label: "Directly to consumers", value: 4 },
  { label: "Exporters", value: 5 },
  { label: "Processors", value: 6 },
];
export const freeTime = [
  { name: "watch_tv", label: "Watching TV" },
  { name: "listen_radio", label: "Listening Radio" },
  { name: "social_media", label: "Using social media" },
  { name: "read_newspaper", label: "Reading newspaper" },
  { name: "gossip_tea_stall", label: "Gossiping at the tea stall" },
];

// attendance type array
export const attendanceTypeArray = [
  {
    value: 101,
    label: `Work From Home`,
  },
  {
    value: 102,
    label: `On Field Duty`,
  },
  {
    value: 103,
    label: `Physical Office`,
  },
  {
    value: 201,
    label: `On Leave`,
  },
];

// number to text
export const numberToTextNumber = [
  "Zero",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
  "Twenty",
];

// leave type
export const leaveTypeListForEmail = [
  {
    value: 1,
    label: `Casual`,
  },
  {
    value: 2,
    label: `Anual`,
  },
  {
    value: 3,
    label: `Sick`,
  },
  {
    value: 4,
    label: `Menstrual`,
  },
  {
    value: 5,
    label: `Maternity`,
  },
  {
    value: 6,
    label: `Reimburse`,
  },
];

// badge color for table status
export const badgeColor = [
  {
    code: 1001,
    name: "danger",
  },
  {
    code: 1002,
    name: "primary",
  },
  {
    code: 1003,
    name: "success",
  },
  {
    code: 1004,
    name: "warning",
  },
  {
    code: 1005,
    name: "danger",
  },
];

// action status code
export const actionStatusCode = [
  {
    code: 1001,
    name: "Pending",
  },
  {
    code: 1002,
    name: "In Progress",
  },
  {
    code: 1003,
    name: "Resovled",
  },
  {
    code: 1004,
    name: "Deducted",
  },
  {
    code: 1005,
    name: "Rejected",
  },
];

export const BLANK_PROFILE = `${process.env.PUBLIC_URL}/img/profile.png`;

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};
