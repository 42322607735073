import React from "react";
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import FormField from "./FormField";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_DESIGNATION, UPDATE_DESIGNATION } from "../../GraphQL/Mutation";
import { successMessage, warningMessage } from "../../containers/ReactToastify";
import { GET_ALL_DEPARTMENT } from "../../GraphQL/Queries";
import { SelectField } from "@/shared/components/form/Select";
const UserDesignationCreate = () => {
  const history = useHistory();

  const updateDesignationtData = history?.location?.state?.designation;

  const {
    data: departmentData,
    loading: departmentLoading,
    error: departmentError,
  } = useQuery(GET_ALL_DEPARTMENT);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [createDesignation] = useMutation(CREATE_DESIGNATION);
  const [updateDesignation] = useMutation(UPDATE_DESIGNATION);

  const onSubmit = async (data) => {
    const desigData = {
      name: data?.name,
      description: data?.description,
      dep_id: data?.dept_id?.value,
    };

    if (updateDesignationtData?.id) {
      const result = await updateDesignation({
        variables: {
          ...desigData,
          id: updateDesignationtData?.id,
        },
      });
      if (result?.data?.updateDesignation?.result_code == "002") {
        warningMessage(result?.data?.updateDesignation?.status, 1500);
      }
      if (result?.data?.updateDesignation?.result_code == "000") {
        successMessage(result?.data?.updateDesignation?.status, 1500);
        history.push("/user/designation");
      }
    } else {
      const result = await createDesignation({
        variables: desigData,
      });
      if (result?.data?.createDesignation?.result_code == "002") {
        warningMessage(result?.data?.createDesignation?.status, 1500);
      }
      if (result?.data?.createDesignation?.result_code == "000") {
        successMessage(result?.data?.createDesignation?.status, 1500);
        history.push("/user/designation");
      }
    }
  };
  return (
    <Col md={12}>
      <Col md={12}>
        <h3 className="page-title">
          {updateDesignationtData?.id ? "Update" : "New"} Designation
        </h3>
      </Col>
      <Card>
        <CardBody>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Col md={8} className="mx-auto">
              <Row>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">Name</label>
                    <div className="form__form-group-field">
                      <FormField
                        name="name"
                        component="input"
                        control={control}
                        errors={errors}
                        rules={{ required: "This is required field" }}
                        type="text"
                        defaultValue={updateDesignationtData?.name}
                        placeholder={`Enter designation name`}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">Department</label>
                    <div className="form__form-group-field">
                      <FormField
                        name="dept_id"
                        control={control}
                        errors={errors}
                        rules={{ required: "This is required field" }}
                        component={SelectField}
                        options={departmentData?.getAllDepartments?.map(
                          (data) => ({
                            label: data?.name,
                            value: data?.id,
                          })
                        )}
                        defaultValue={departmentData?.getAllDepartments
                          ?.map((data) => ({
                            label: data?.name,
                            value: data?.id,
                          }))
                          ?.find(
                            (item) =>
                              item?.value == updateDesignationtData?.dep_id
                          )}
                        placeholder="Select department"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">
                      Desicription
                    </label>
                    <div className="form__form-group-field">
                      <FormField
                        name="description"
                        component="textarea"
                        errors={errors}
                        control={control}
                        rules={{ required: "This is required field" }}
                        type="text"
                        defaultValue={updateDesignationtData?.description}
                        placeholder={`Enter designation description`}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        type="button"
                        onClick={() => history.push("/user/designation")}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                      <Button color="primary" type="submit">
                        {updateDesignationtData?.id ? "Update" : "Create"}
                      </Button>
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserDesignationCreate;
