import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  Table,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  ButtonToolbar,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import Clock from "react-live-clock";
import { usePagination } from "../../../utils/helpers";
import { Pagination } from "@mui/material";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/react";
import PopoverOnClick from "../../../utils/HelperComponent/PopoverOnClick";
import UserListSkeleton from "../../UserManagement/UserListSkeleton";
import { decryption } from "../../HelperFunctions";
import AuthorizeButton from "./AuthorizeButton";
import { UPDATE_ATTENDANCE } from "../../../GraphQL/Mutation";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";

const AttendanceTable = ({ allAttendances, isLoadingAttendance, roles }) => {
  const todayCheck = moment(new Date()).format("YYYY-MM-DD");
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(allAttendances?.length / PER_PAGE);
  const pageData = usePagination(allAttendances, PER_PAGE);

  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );

  const [updateAttendance, { loading, error }] = useMutation(UPDATE_ATTENDANCE);

  const handleClick = async (item) => {
    const authorize = item?.attendanceInfo?.isAuthorized;

    // Show confirmation dialog using SweetAlert2
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      // Proceed only if the user confirms the action
      if (result.isConfirmed) {
        try {
          const { data } = await updateAttendance({
            variables: {
              attendanceId: item?.id,
              isAuthorized: !authorize,
            },
          });

          Swal.fire({
            title: "Updated Successfully!",
            text: "Your attendance authorization has been updated.",
            icon: "success",
          });
        } catch (err) {
          console.error("Error updating attendance:", err.message);
          Swal.fire({
            title: "Error",
            text: "There was an issue updating the attendance.",
            icon: "error",
          });
        }
      }
    });
  };
  let [color, setColor] = useState("rgb(54, 215, 183)");
  const override = css`
    margin: 0 auto;
    display: block;
    width: 100px;
  `;
  // <PropagateLoader color={color} css={override} loading={true} size={10} />

  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };
  // attendance type array
  const attendanceTypeArray = [
    {
      value: 101,
      label: `Work From Home`,
    },
    {
      value: 102,
      label: `On Field Duty`,
    },
    {
      value: 201,
      label: `On Leave`,
    },
  ];
  const [popoverToggle, setPopoverToggle] = useState(false);

  return (
    <>
      <Table role="table" striped hover responsive>
        <thead>
          <tr role="row">
            <th>#</th>
            <th>Name</th>
            <th>Last Access</th>
            <th>Date</th>
            <th>First login</th>
            <th>Last logout</th>
            <th>Duration</th>
            {permissions?.includes("deny_leave") && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {isLoadingAttendance ? (
            <tr>
              <td colSpan="7">
                {/* <UserListSkeleton /> */}
                <PropagateLoader
                  color={color}
                  css={override}
                  loading={true}
                  size={10}
                />
              </td>
            </tr>
          ) : allAttendances === undefined ? (
            <tr>
              <td colSpan="7" className="text-center">
                <PropagateLoader
                  color={color}
                  css={override}
                  loading={true}
                  size={10}
                />
              </td>
            </tr>
          ) : allAttendances?.length > 0 ? (
            pageData?.currentData()?.map((item, index) => (
              <tr
                className={`${
                  (item?.attendance == false && "bg-not-attendance") ||
                  (item?.lateEntry == true && "bg-not-attendance")
                }`}
                key={item?.id}
              >
                <th>{item?.indexId ? item?.indexId : index + 1}</th>
                <td>
                  {item?.employeeInfo?.user?.name}({item?.rfid})
                </td>
                <td>{item?.user_ip ? item?.user_ip : "—"}</td>
                <td>{item?.login_date ? item?.login_date : "—"}</td>
                <td>
                  {item?.login_time
                    ? `${moment(item?.login_time).format("hh:mm:ss A")} `
                    : "—"}
                  {item?.login_device ? `(${item?.login_device})` : null}
                  {item?.type ? (
                    item?.type == 201 ? (
                      <PopoverOnClick
                        id={`on_leave${item?.id}`}
                        value="On Leave"
                        buttonValue="OL"
                      />
                    ) : item?.type == 101 ? (
                      <PopoverOnClick
                        id={`wfh${item?.id}`}
                        value="Work From Home"
                        buttonValue="WFH"
                      />
                    ) : item?.type == 102 ? (
                      <PopoverOnClick
                        id={`ofd${item?.id}`}
                        value="On Field Duty"
                        buttonValue="OFD"
                      />
                    ) : null
                  ) : null}
                  {item?.reason ? (
                    <PopoverOnClick
                      color="primary"
                      id={`reason${item?.id}`}
                      value={item.reason}
                      buttonValue="?"
                    />
                  ) : null}
                </td>
                <td>
                  {item?.logout_time
                    ? moment(item?.logout_time).format("hh:mm:ss A")
                    : "—"}{" "}
                  {item?.logout_device ? `(${item?.logout_device})` : null}
                </td>
                <td>
                  {item?.logout_time ? (
                    moment
                      .utc(
                        moment(item?.logout_time, "YYYY-MM-DD HH:mm:ss").diff(
                          moment(item?.login_time, "YYYY-MM-DD HH:mm:ss")
                        )
                      )
                      .format("HH:mm:ss")
                  ) : item?.login_time ? (
                    moment(item?.login_time).format("YYYY-MM-DD") ==
                    moment(new Date()).format("YYYY-MM-DD") ? (
                      <Clock
                        date={moment.utc(
                          moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
                            moment(item?.login_time, "YYYY-MM-DD HH:mm:ss")
                          )
                        )}
                        format={"HH:mm:ss"}
                        ticking={true}
                        timezone={"utc"}
                      />
                    ) : (
                      "—"
                    )
                  ) : (
                    "—"
                  )}
                </td>
                {permissions?.includes("deny_leave") && (
                  <td>
                    <AuthorizeButton
                      item={item}
                      authorized={item?.isAuthorized}
                      onClick={handleClick}
                    />
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-success text-center">
                <b>Today you have no attendance.</b>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {<Pagination count={count} page={page} onChange={handleChange} />}
    </>
  );
};

export default AttendanceTable;
