/* eslint-disable react/jsx-indent */
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { loginInfo } from "../../../../utils/helpers";
import { decryption } from "../../../HelperFunctions";
import {
  History,
  HolydayForm,
  Holydays,
  LeaveForm,
  Mail,
  Penalty,
  PenaltyForm,
  Reports,
  Settings,
  WfhOdForm,
  LeaveDeduction,
} from "../../../Leave";
import OverTime from "../../../Leave/components/OverTime";
import OverTimeList from "../../../Leave/components/OverTimeList";

export default () => {
  const permission = JSON.parse(localStorage?.getItem("permissions"));
  const loggedInfo = loginInfo();

  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          loggedInfo?.id ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/log_in",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  const routerData = [
    {
      path: "/leave/new",
      permissionName: "create_leave",
      redirectTo: "/404",
      componentName: <LeaveForm />,
    },
    {
      path: "/leave/over-time",
      permissionName: "create_leave",
      redirectTo: "/404",
      componentName: <OverTime />,
    },
    {
      path: "/leave/over-time-list",
      permissionName: "create_leave",
      redirectTo: "/404",
      componentName: <OverTimeList />,
    },
    {
      path: "/leave/history",
      permissionName: "history_leave",
      redirectTo: "/404",
      componentName: <History />,
    },
    {
      path: "/leave/wfh-od",
      permissionName: "create_wfh_od",
      redirectTo: "/404",
      componentName: <WfhOdForm />,
    },
    {
      path: "/leave/settings",
      permissionName: "settings_leave",
      redirectTo: "/404",
      componentName: <Settings />,
    },
    {
      path: "/leave/reports",
      permissionName: "reports_leave",
      redirectTo: "/404",
      componentName: <Reports />,
    },
    {
      path: "/leave/mail",
      permissionName: "request_leave",
      redirectTo: "/404",
      componentName: <Mail />,
    },
    {
      path: "/leave/deduction",
      permissionName: "leave_deduction",
      redirectTo: "/404",
      componentName: <LeaveDeduction />,
    },
    {
      path: "/leave/holydays",
      permissionName: "view_holydays",
      redirectTo: "/404",
      componentName: <Holydays />,
    },
    {
      path: "/leave/new-holyday",
      permissionName: "create_holyday",
      redirectTo: "/404",
      componentName: <HolydayForm />,
    },
    {
      path: "/leave/penalty",
      permissionName: "view_penalty",
      redirectTo: "/404",
      componentName: <Penalty />,
    },
    {
      path: "/leave/review-penalty",
      permissionName: "create_penalty",
      redirectTo: "/404",
      componentName: <PenaltyForm />,
    },
    {
      path: "/leave/review/:id/:date",
      permissionName: "create_penalty",
      redirectTo: "/404",
      componentName: <PenaltyForm />,
    },
  ];
  return (
    <Switch>
      {routerData?.map((item, index) => (
        <PrivateRoute key={index + 1} path={item?.path}>
          {decryption(permission)?.includes(item?.permissionName) ? (
            item?.componentName
          ) : (
            <Redirect to={item?.redirectTo} />
          )}
        </PrivateRoute>
      ))}
    </Switch>
  );
};
