// import appConfigReducer from '@/redux/reducers/appConfigReducer';
import appConfigReducer from "../../redux/reducers/appConfigReducer";
import {
  authReducer,
  blocksShadowsReducer,
  cryptoTableReducer,
  customizerReducer,
  newOrderTableReducer,
  pokemonReducer,
  roundBordersReducer,
  rtlReducer,
  sidebarReducer,
  themeReducer,
} from "../../redux/reducers/index";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { leaveReducer, pendingLeaveReducer } from "../../redux/reducers";
import { deptReducer } from "../../redux/reducers/deptReducer";
import { userReducer } from "../../redux/reducers/userReducer";

const reducer = combineReducers({
  theme: themeReducer,
  rtl: rtlReducer,
  roundBorders: roundBordersReducer,
  blocksShadows: blocksShadowsReducer,
  appConfig: appConfigReducer,
  cryptoTable: cryptoTableReducer,
  customizer: customizerReducer,
  newOrder: newOrderTableReducer,
  sidebar: sidebarReducer,
  user: authReducer,
  pokemon: pokemonReducer,
  userAction: userReducer,
  department: deptReducer,
  pendingLeave: pendingLeaveReducer,
  leave: leaveReducer,
});
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
