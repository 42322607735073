import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { useHistory } from "react-router";
import SidebarCategory from "./SidebarCategory";
import SidebarLink from "./SidebarLink";
import jwt from "jsonwebtoken";
import { useQuery } from "@apollo/client";
import { GET_ALL_ADDITIONAL_INFO_BY_USER_ID } from "../../../../GraphQL/Queries";
import { decryption } from "../../../HelperFunctions";
import { customerInfo } from "../../../../utils/helpers";

const SidebarContent = ({
  onClick,
  changeToLight,
  changeToDark,
  sidebarCollapse,
}) => {
  const token = localStorage?.getItem("token");
  const history = useHistory();

  // const user = decodeToken(token);
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));
  const [user, setuser] = useState({});
  const loginCustomerInfo = customerInfo();

  // get user id from local storage
  useEffect(() => {
    const token = localStorage?.getItem("token");
    jwt.verify(token, "test", function (err, decoded) {
      if (err) {
        localStorage.removeItem("token");
      }
      setuser(decoded);
    });
  }, []);

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {roles?.includes("System Admin") ||
        roles?.includes("Head Of Department") ||
        roles?.includes("Employee") ||
        roles?.includes("System Manager") ||
        roles?.includes("Human Resource (HR)") ? (
          <SidebarLink
            icon="briefcase"
            title="Dashboard"
            route="/"
            onClick={onClick}
          />
        ) : null}

        {/* user management route */}
        {roles?.includes("Head Of Department") ||
        roles?.includes("System Admin") ||
        roles?.includes("	Executive, Admin & HR") ||
        roles?.includes("System Manager") ? (
          <SidebarCategory title="User Management" icon="users">
            {permissions?.includes("create_employee") && (
              <SidebarLink
                title="User Create"
                route="/user/create"
                onClick={onClick}
              />
            )}
            {permissions?.includes("view_employee") && (
              <SidebarLink
                title="User List"
                route="/user/list"
                onClick={onClick}
              />
            )}
            {permissions?.includes("view_roles") && (
              <SidebarLink
                title="Roles"
                route="/user/roles"
                onClick={onClick}
              />
            )}
            {permissions?.includes("view_permission") && (
              <SidebarLink
                title="Permission"
                route="/user/permission"
                onClick={onClick}
              />
            )}
            {permissions?.includes("view_designation") && (
              <SidebarLink
                title="User Designation"
                route="/user/designation"
                onClick={onClick}
              />
            )}
            {permissions?.includes("view_designation") && (
              <SidebarLink
                title="User Report"
                route="/user/report"
                onClick={onClick}
              />
            )}
          </SidebarCategory>
        ) : null}

        {/* attendance route */}
        {/* {roles?.includes('Head Of Department') || roles?.includes('Employee') || roles?.includes('System Admin') ? <SidebarLink icon="book" title="Attendance" route="/attendance" onClick={onClick} /> : null} */}

        {/* leave management route */}
        {roles?.includes("Head Of Department") ||
        roles?.includes("Employee") ||
        roles?.includes("System Admin") ||
        roles?.includes("Human Resource (HR)") ? (
          <SidebarCategory title="Attendance Management" icon="bicycle">
            {permissions?.includes("create_leave") && (
              <SidebarLink
                title="Leave Apply"
                route="/leave/new"
                onClick={onClick}
              />
            )}
            {permissions?.includes("create_leave") && (
              <SidebarLink
                title="Overtime Work"
                route="/leave/over-time"
                onClick={onClick}
              />
            )}
            {permissions?.includes("create_leave") && (
              <SidebarLink
                title="Overtime Work List"
                route="/leave/over-time-list"
                onClick={onClick}
              />
            )}
            {permissions?.includes("create_wfh_od") && (
              <SidebarLink
                title="Home / Field Office Apply"
                route="/leave/wfh-od"
                onClick={onClick}
              />
            )}
            {permissions?.includes("history_leave") && (
              <SidebarLink
                title="History"
                route="/leave/history"
                onClick={onClick}
              />
            )}
            {permissions?.includes("view_penalty") && (
              <SidebarLink
                title="Penalty"
                route="/leave/penalty"
                onClick={onClick}
              />
            )}
            {permissions?.includes("request_leave") && (
              <SidebarLink
                title="Leave Request"
                route="/leave/mail"
                onClick={onClick}
              />
            )}
            {permissions?.includes("leave_deduction") && (
              <SidebarLink
                title="Leave Deduction"
                route="/leave/deduction"
                onClick={onClick}
              />
            )}
            {permissions?.includes("settings_leave") && (
              <SidebarLink
                title="Settings"
                route="/leave/settings"
                onClick={onClick}
              />
            )}
            {permissions?.includes("reports_leave") && (
              <SidebarLink
                title="Reports"
                route="/leave/reports"
                onClick={onClick}
              />
            )}
          </SidebarCategory>
        ) : null}

        {/* project proposal route */}
        {loginCustomerInfo?.role == "Customer" ||
        roles?.includes("System Admin") ||
        roles?.includes("Head Of Department") ? (
          <SidebarCategory title="Project Proposal" icon="bullhorn">
            {loginCustomerInfo?.role == "Customer" ? (
              <SidebarLink
                title="Add New"
                route="/project/new"
                onClick={onClick}
              />
            ) : null}
            <SidebarLink title="List" route="/project/list" onClick={onClick} />
            {loginCustomerInfo?.role == "Customer" ||
            roles?.includes("Head Of Department") ? (
              <SidebarLink
                title="Discussion"
                route="/project/discussion"
                onClick={onClick}
              />
            ) : null}
          </SidebarCategory>
        ) : null}

        {/* department route */}
        {roles?.includes("System Admin") ||
        roles?.includes("Head Of Department") ||
        roles?.includes("Employee") ||
        roles?.includes("System Manager") ||
        roles?.includes("Human Resource (HR)") ? (
          <SidebarLink
            icon="layers"
            title="Department"
            route="/department/list"
            onClick={onClick}
          />
        ) : null}

        {/* department route */}
        {roles?.includes("System Admin") ||
        roles?.includes("Head Of Department") ||
        roles?.includes("Employee") ||
        roles?.includes("System Manager") ||
        roles?.includes("Human Resource (HR)")
          ? permissions?.includes("view_holydays") && (
              <SidebarLink
                icon="apartment"
                title="Company"
                route="/company/list"
                onClick={onClick}
              />
            )
          : null}

        {/* notice Route */}
        {/* {roles?.includes("Head Of Department") ||
          roles?.includes("System Admin") ||
          roles?.includes("Executive, Admin & HR") ||
          roles?.includes("System Manager") ||
          (roles?.includes("Employee") && (
            <SidebarCategory title="Notices" icon="inbox">
          
              {permissions?.includes("create_employee") && (
                <SidebarLink
                  title="Create Notice"
                  route="/notices/new"
                  onClick={onClick}
                />
              )}

           
              <SidebarLink
                title="Notices List"
                route="/notices/list"
                onClick={onClick}
              />

             
              {(loginCustomerInfo?.role === "Customer" ||
                roles?.includes("Head Of Department")) && (
                <SidebarLink
                  title="Notice Discussion"
                  route="/notices/discussion"
                  onClick={onClick}
                />
              )}
            </SidebarCategory>
          ))} */}

        {roles?.includes("System Admin") ||
        roles?.includes("Head Of Department") ||
        roles?.includes("Employee") ||
        roles?.includes("System Manager") ||
        roles?.includes("Human Resource (HR)")
          ? permissions?.includes("view_holydays") && (
              <SidebarCategory title="Notices" icon="inbox">
                {permissions?.includes("create_employee") && (
                  <SidebarLink
                    title="Create Notice"
                    route="/notice/new"
                    onClick={onClick}
                  />
                )}

                <SidebarLink
                  title="Notices List"
                  route="/notice/list"
                  onClick={onClick}
                />
              </SidebarCategory>
            )
          : null}

        {/* holiday route */}
        {roles?.includes("System Admin") ||
        roles?.includes("Head Of Department") ||
        roles?.includes("Employee") ||
        roles?.includes("System Manager") ||
        roles?.includes("Human Resource (HR)")
          ? permissions?.includes("view_holydays") && (
              <SidebarLink
                icon="moon"
                title="Holidays"
                route="/leave/holydays"
                onClick={onClick}
              />
            )
          : null}
        {/* icon name found from https://linearicons.com/free/icon/question-circle */}
        <SidebarLink
          route="/iot/switch-board"
          icon="moon"
          title="IoT Switch"
          onClick={onClick}
        />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => {},
};

export default SidebarContent;
