import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import MailIcon from "mdi-react/MailIcon";
import ImageViewer from "react-simple-image-viewer";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_ADDITIONAL_INFO_BY_RFID,
  GET_ALL_DEPARTMENT,
  GET_ALL_LEAVES,
  GET_REST_LEAVE_BY_USER_ID,
  GET_USER_BY_ID,
} from "../../../../GraphQL/Queries";
import moment from "moment";
import { useDispatch } from "react-redux";
import { pendingLeave } from "../../../../redux/actions/pendingLeave";
import {
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  ButtonToolbar,
  Collapse,
} from "reactstrap";
import {
  CREATE_ATTENDANCE_BY_LEAVE,
  UPDATE_LEAVE,
  UPDATE_REWARD,
  UPDATE_USER_LEAVE_ADDITIONAL_INFO,
} from "../../../../GraphQL/Mutation";
import { successMessage, warningMessage } from "../../../ReactToastify";
import Swal from "sweetalert2";
import { decryption, leaveTypeListForEmail } from "../../../HelperFunctions";
import jwt from "jsonwebtoken";
import axios from "axios";
import { countWeekends, loginInfo } from "../../../../utils/helpers";

const TopbarLeaveRequest = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const history = useHistory();
  const [email, setEmail] = useState({});
  const [modal, setModal] = useState(false);
  const [updateRestLeave] = useMutation(UPDATE_USER_LEAVE_ADDITIONAL_INFO);
  const [createAttendanceByLeave] = useMutation(CREATE_ATTENDANCE_BY_LEAVE);
  const [attachment, setAttachment] = useState({});
  const loggedInfo = loginInfo();
  // sorted function
  Array.prototype.sortBy = function (p) {
    return this.slice(0).sort(function (a, b) {
      return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
    });
  };
  // get login user information
  const token = localStorage?.getItem("token");
  let userID;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    userID = decoded?.id;
  });

  // ** redwar mutation
  const [updateReward] = useMutation(UPDATE_REWARD);

  // get user information
  const LoadUserInformation = useQuery(GET_USER_BY_ID, {
    skip: !userID,
    variables: {
      id: userID,
    },
  });
  const [reliverId, setReliverId] = useState(null);
  const LoadAdditionalInfoByRfid = useQuery(GET_ALL_ADDITIONAL_INFO_BY_RFID, {
    skip: !reliverId,
    variables: {
      rfid: reliverId,
    },
  });

  // it is user for 'allLeaves' state update
  const [anyChangeLeave, setAnyChangeLeave] = useState(false);
  useEffect(() => {
    setCheckRestLeaveUserId(email?.user_id);
  }, [email]);
  // get additional information for check rest of leave when user leave approved
  const [checkRestLeaveUserId, setCheckRestLeaveUserId] = useState(
    email?.user_id
  );
  const LoadAllAdditionalInfo = useQuery(GET_REST_LEAVE_BY_USER_ID, {
    skip: !checkRestLeaveUserId,
    variables: {
      user_id: checkRestLeaveUserId,
    },
  });
  const leaveName = leaveTypeListForEmail
    ?.find((item) => item?.value == email?.leave_type)
    ?.label?.toLowerCase();

  // deparment head of the department email
  const LoadAllDepartment = useQuery(GET_ALL_DEPARTMENT);

  const [updateLeave] = useMutation(UPDATE_LEAVE);
  const modalHandler = () => {
    setModal((prevSate) => !prevSate);
  };

  const collapseMail = () => {
    setIsCollapsed(!isCollapsed);
  };

  // dispatch for pending leave mail
  const dispatch = useDispatch();

  // weekend check for attendance
  const IsThereWeekend = (startDate, endDate) => {
    // date format: new Date("2022-03-01");
    let weekendDates = [];
    const initialState = startDate;
    initialState.setDate(startDate.getDate());
    for (
      let date = initialState;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      if (date.getDay() < 5) {
        weekendDates.push(new Date(date.toString()));
      }
    }
    return weekendDates;
  };

  // load all leave mail/request
  const [allLeaves, setAllLeaves] = useState([]);
  const LoadAllLeaves = useQuery(GET_ALL_LEAVES);
  useEffect(() => {
    if (LoadAllLeaves?.data?.getAllLeaves) {
      // setAllLeaves((LoadAllLeaves?.data?.getAllLeaves?.filter(item => item?.leave_status == "Pending" && item?.user?.additionalInfo?.dept_id == LoadUserInformation?.data?.getUserById?.additionalInfo?.dept_id))?.sortBy('createdAt'));
      let emp = [];
      LoadAllDepartment?.data?.getAllDepartments
        ?.filter(
          (dept) => dept.user_id == LoadUserInformation?.data?.getUserById?.id
        )
        ?.map((dept) => {
          LoadAllLeaves?.data?.getAllLeaves
            ?.filter(
              (item) =>
                item?.leave_status == "Pending" &&
                item?.user?.additionalInfo?.dept_id == dept.id
            )
            ?.map((employee) => emp.push(employee));
        });
      setAllLeaves(emp?.sortBy("createdAt"));
      LoadAllLeaves?.refetch();
    }
  }, [LoadAllLeaves?.data?.getAllLeaves, anyChangeLeave]);

  // attendance type array
  const attendanceTypeArray = [
    {
      value: 101,
      label: `Work From Home`,
    },
    {
      value: 102,
      label: `On Field Duty`,
    },
    {
      value: 201,
      label: `On Leave`,
    },
  ];

  const approvedHandler = async (email, type) => {
    console.log(email, type);
    // deny condition here
    if (type == "deny") {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Reason of deny",
        inputPlaceholder: "Type reason of deny...",
        confirmButtonText: "Deny",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        allowOutsideClick: false,
      });

      if (text) {
        const deniedBody = `
            <!DOCTYPE html>
            <html>
              <head>
                <meta name="viewport" content="width=device-width, initial-scale=1">
              </head>
              <body>
                <p>Dear ${email?.user?.name?.split(" ")[0]},</p>
                <p>Your application has been <b>denied</b>. The reason of denied:</p>
                <p>${text}</p>
                <p>Best Regards,</p>
                <p>${
                  LoadAllDepartment?.data?.getAllDepartments?.find(
                    (item) => item?.id == email?.user?.additionalInfo?.dept_id
                  )?.user?.name
                }</p>
                <p>&mdash;</p>
                <p>On ${moment(new Date(Number(email?.createdAt))).format(
                  "dddd, Do MMMM YYYY, h:mm:ss a"
                )} <i>${email?.user?.name}&#60;${
          email?.user?.email
        }&#62;</i> wrote:</p>
                <p>Start: ${moment(email?.start_date).format(
                  "DD MMMM YYYY"
                )}</p>
                <p>End: ${moment(email?.end_date).format("DD MMMM YYYY")}</p>
                <p>Total: ${
                  moment
                    .duration(
                      new Date(email?.end_date) - new Date(email?.start_date)
                    )
                    .asDays() + 1
                }${
          moment
            .duration(new Date(email?.end_date) - new Date(email?.start_date))
            .asDays() +
            1 >
          1
            ? " Days"
            : " Day"
        }</p>
                <p>The reason of leave: <br /> ${email?.leave_reason}</p>
              </body>
            </html>`;
        const result = await updateLeave({
          variables: {
            ...email,
            leave_status: "Denied",
            deny_reason: text,
            cc_email: "",
            subject_email: `[Denied] Application for ${
              leaveTypeList?.find((item) => item?.value == email?.leave_type)
                ?.label
            } leave`,
            text_email: `Your application has been denied.`,
            html_email: deniedBody,
            to_email: email?.user?.email,
          },
        });
        if (result?.data?.updateLeave?.result_code == "000") {
          successMessage("Leave application has been denied.", 3000);
          setAnyChangeLeave((prevState) => !prevState);
          history.push("/leave/mail");
        }
        if (result?.data?.updateLeave?.result_code == "002") {
          warningMessage(result?.data?.updateLeave?.status, 3000);
        }
      }
    }

    // approved condition here
    const approvedBody = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1">
      </head>
      <body>
        <p>Dear ${email?.user?.name?.split(" ")[0]},</p>
        <p>Your application has been approved.</p>
        <p>Best Regards,</p>
        <p>${
          LoadAllDepartment?.data?.getAllDepartments?.find(
            (item) => item?.id == email?.user?.additionalInfo?.dept_id
          )?.user?.name
        }</p>
        <p>&mdash;</p>
        <p>On ${moment(new Date(Number(email?.createdAt))).format(
          "dddd, Do MMMM YYYY, h:mm:ss a"
        )} <i>${email?.user?.name}&#60;${email?.user?.email}&#62;</i> wrote:</p>
        <p>Start: ${moment(email?.start_date).format("DD MMMM YYYY")}</p>
        <p>End: ${moment(email?.end_date).format("DD MMMM YYYY")}</p>
        <p>Total: ${
          moment
            .duration(new Date(email?.end_date) - new Date(email?.start_date))
            .asDays() + 1
        }${
      moment
        .duration(new Date(email?.end_date) - new Date(email?.start_date))
        .asDays() +
        1 >
      1
        ? " Days"
        : " Day"
    }</p>
        <p>The reason of leave: <br /> ${email?.leave_reason}</p>
      </body>
    </html>`;

    if (type == "approve") {
      const startDate = new Date(email?.start_date);
      const endDate = new Date(email?.end_date);
      const diffInTime = endDate.getTime() - startDate.getTime();
      const diffInDays = IsThereWeekend(
        new Date(email?.start_date),
        new Date(email?.end_date)
      )?.length;
      // const diffInDays = diffInTime / (1000 * 3600 * 24) + 1;
      const newValue =
        LoadAllAdditionalInfo?.data?.getRestLeaveByUserId[leaveName] -
        diffInDays;
      let newAllAdditionalInfo = {
        ...LoadAllAdditionalInfo?.data?.getRestLeaveByUserId,
        [leaveName]: newValue,
      };
      if (
        diffInDays <=
        LoadAllAdditionalInfo?.data?.getRestLeaveByUserId[leaveName]
      ) {
        await updateRestLeave({
          variables: newAllAdditionalInfo,
        });
        const result = await updateLeave({
          variables: {
            ...email,
            leave_status: "Approved",
            deny_reason: "",
            cc_email: LoadAllDepartment?.data?.getAllDepartments?.find(
              (item) => item?.id == 2
            )?.user?.email,
            subject_email: `[Approved] Application for ${
              leaveTypeList?.find((item) => item?.value == email?.leave_type)
                ?.label
            } leave`,
            text_email: `Your application has been approved.`,
            html_email: approvedBody,
            to_email: email?.user?.email,
          },
        });
        LoadAllAdditionalInfo.refetch();

        // attendance create according to leave date
        let returnValue;
        await axios
          .get("https://www.cloudflare.com/cdn-cgi/trace")
          .then(function (response) {
            returnValue = response?.data
              .trim()
              .split("\n")
              .reduce(function (obj, pair) {
                pair = pair.split("=");
                return (obj[pair[0]] = pair[1]), obj;
              }, {});
          });
        const leaveData = {
          startDate: moment(new Date(email?.start_date)).format("YYYY-MM-DD"),
          endDate: moment(new Date(email?.end_date)).format("YYYY-MM-DD"),
          rfid: email?.user?.additionalInfo?.rfid,
          user_id: email?.user?.additionalInfo?.user_id,
          created_user_id: loggedInfo?.id,
          user_ip: returnValue?.ip,
          type: 201,
        };
        const attendance = await createAttendanceByLeave({
          variables: leaveData,
        });

        if (result?.data?.updateLeave?.result_code == "000") {
          successMessage(result?.data?.updateLeave?.status, 3000);
          setAnyChangeLeave((prevState) => !prevState);
          history.push("/leave/mail");
        }
        if (result?.data?.updateLeave?.result_code == "002") {
          warningMessage(result?.data?.updateLeave?.status, 3000);
        }

        if (attendance?.data?.createAttendanceByLeave?.result_code == "000") {
          successMessage(
            attendance?.data?.createAttendanceByLeave?.status,
            3000
          );
          setAnyChangeLeave((prevState) => !prevState);
          history.push("/leave/mail");
        }
        if (attendance?.data?.createAttendanceByLeave?.result_code == "002") {
          warningMessage(
            attendance?.data?.createAttendanceByLeave?.status,
            3000
          );
        }
      } else {
        debugger;

        console.log("++++++++++++++++ TEst +++++++++++++++++++++++");
        warningMessage(
          `Applicant not eligible for leave. ${leaveName.toUpperCase()} leave ${
            LoadAllAdditionalInfo?.data?.getRestLeaveByUserId[leaveName]
          } days left.`,
          3000
        );
      }
    }

    if (type == "cancel") {
      const diffInDays = IsThereWeekend(
        new Date(email?.start_date),
        new Date(email?.end_date)
      )?.length;
      // const diffInDays = diffInTime / (1000 * 3600 * 24) + 1;
      const newValue =
        LoadAllAdditionalInfo?.data?.getRestLeaveByUserId[leaveName] +
        diffInDays;
      let newAllAdditionalInfo = {
        ...LoadAllAdditionalInfo?.data?.getRestLeaveByUserId,
        [leaveName]: newValue,
      };
      const result3 = await updateRestLeave({
        variables: newAllAdditionalInfo,
      });
      LoadAllAdditionalInfo.refetch();
      const result = await updateLeave({
        variables: {
          ...email,
          leave_status: "Pending",
          deny_reason: "",
        },
      });
      const result2 = await deleteAttendanceByCanceledLeave({
        variables: {
          rfid: email?.user?.additionalInfo?.rfid,
          startDate: email?.start_date,
          endDate: email?.end_date,
        },
      });

      // leave update message
      if (result?.data?.updateLeave?.result_code == "000") {
        successMessage(result?.data?.updateLeave?.status, 3000);
        setAnyChangeLeave((prevState) => !prevState);
        history.push("/leave/mail");
      }
      if (result?.data?.updateLeave?.result_code == "002") {
        warningMessage(result?.data?.updateLeave?.status, 3000);
      }

      // attendance delete message
      if (
        result2?.data?.deleteAttendanceByCanceledLeave?.result_code == "000"
      ) {
        successMessage(
          result2?.data?.deleteAttendanceByCanceledLeave?.status,
          3000
        );
        setAnyChangeLeave((prevState) => !prevState);
        history.push("/leave/mail");
      }
      if (
        result2?.data?.deleteAttendanceByCanceledLeave?.result_code == "002"
      ) {
        warningMessage(
          result2?.data?.deleteAttendanceByCanceledLeave?.status,
          3000
        );
      }

      // rest leave update message
      if (result3?.data?.updateRestLeave?.result_code == "000") {
        successMessage(result3?.data?.updateRestLeave?.status, 3000);
        setAnyChangeLeave((prevState) => !prevState);
        history.push("/leave/mail");
      }
      if (result3?.data?.updateRestLeave?.result_code == "002") {
        warningMessage(result3?.data?.updateRestLeave?.status, 3000);
      }
    }

    if (type == "wfh_od") {
      console.log(email?.user?.additionalInfo, "fjlasdkfjasdlk");
      // ** count rewards in weekend duty start
      const weekendCounts = countWeekends(
        new Date(email?.start_date),
        new Date(email?.end_date)
      );
      if (weekendCounts > 0) {
        await updateReward({
          variables: {
            input: {
              user_id: email?.user_id,
              reward: weekendCounts,
            },
          },
        });
      }

      // ** count rewards in weekend duty end
      const wfhOdApprovedBody = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1">
        </head>
        <body>
          <p>Dear ${email?.user?.name?.split(" ")[0]},</p>
          <p>Your application has been approved.</p>
          <p>Best Regards,</p>
          <p>${
            LoadAllDepartment?.data?.getAllDepartments?.find(
              (item) => item?.id == email?.user?.additionalInfo?.dept_id
            )?.user?.name
          }</p>
          <p>&mdash;</p>
          <p>On ${moment(new Date(Number(email?.createdAt))).format(
            "dddd, Do MMMM YYYY, h:mm:ss a"
          )} <i>${email?.user?.name}&#60;${
        email?.user?.email
      }&#62;</i> wrote:</p>
          <p>Start: ${moment(email?.start_date).format("DD MMMM YYYY")}</p>
          <p>End: ${moment(email?.end_date).format("DD MMMM YYYY")}</p>
          <p><b>Total: </b>${
            IsThereWeekend(
              new Date(email?.start_date),
              new Date(email?.end_date)
            )?.length
          } ${
        IsThereWeekend(new Date(email?.start_date), new Date(email?.end_date))
          ?.length > 1
          ? "Days"
          : "Day"
      }</p>
          <p>The reason of leave: <br /> ${email?.leave_reason}</p>
        </body>
      </html>`;
      const result = await updateLeave({
        variables: {
          ...email,
          leave_status: "Approved",
          deny_reason: "",
          // cc_email: "tanvirahammed.ipage@gmail.com",
          cc_email: "ayon@ipageglobal.com",
          // cc_email: LoadAllDepartment?.data?.getAllDepartments?.find(item => item?.id == 2)?.user?.email,
          subject_email: `[Approved] Application for ${
            attendanceTypeArray?.find(
              (item) => item?.value == email?.leave_type
            )?.label
          }`,
          text_email: `Your application has been approved.`,
          html_email: wfhOdApprovedBody,
          to_email: email?.user?.email,
        },
      });
      // attendance create according to leave date
      let returnValue;
      await axios
        .get("https://www.cloudflare.com/cdn-cgi/trace")
        .then(function (response) {
          returnValue = response?.data
            .trim()
            .split("\n")
            .reduce(function (obj, pair) {
              pair = pair.split("=");
              return (obj[pair[0]] = pair[1]), obj;
            }, {});
        });
      const leaveData = {
        startDate: moment(new Date(email?.start_date)).format("YYYY-MM-DD"),
        endDate: moment(new Date(email?.end_date)).format("YYYY-MM-DD"),
        rfid: email?.user?.additionalInfo?.rfid,
        user_id: email?.user?.additionalInfo?.user_id,
        created_user_id: loggedInfo?.id,
        user_ip: returnValue?.ip,
        type: Number(
          attendanceTypeArray?.find((item) => item?.value == email?.leave_type)
            ?.value
        ),
        leave_id: Number(email?.id),
        isWeekendAttendance: true,
      };
      const attendance = await createAttendanceByLeave({
        variables: leaveData,
      });

      if (result?.data?.updateLeave?.result_code == "000") {
        successMessage(result?.data?.updateLeave?.status, 3000);
        setAnyChangeLeave((prevState) => !prevState);
        history.push("/leave/mail");
      }
      if (result?.data?.updateLeave?.result_code == "002") {
        warningMessage(result?.data?.updateLeave?.status, 3000);
      }
      if (attendance?.data?.createAttendanceByLeave?.result_code == "000") {
        successMessage(attendance?.data?.createAttendanceByLeave?.status, 3000);
        setAnyChangeLeave((prevState) => !prevState);
        history.push("/leave/mail");
      }
      if (attendance?.data?.createAttendanceByLeave?.result_code == "002") {
        warningMessage(attendance?.data?.createAttendanceByLeave?.status, 3000);
      }
    }
  };

  // leave type for leave details show
  const leaveTypeList = [
    {
      value: 1,
      label: `Casual`,
    },
    {
      value: 2,
      label: `Anual`,
    },
    {
      value: 3,
      label: `Sick`,
    },
    {
      value: 4,
      label: `Menstrual`,
    },
    {
      value: 5,
      label: `Maternity`,
    },
    {
      value: 6,
      label: `Reimburse`,
    },
  ];
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  // image viewer
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  let url = [];

  if (Object.keys(attachment)?.length === 0) {
    null;
  } else {
    JSON.parse(attachment?.images)?.map((image) => {
      url.push(image?.image);
    });
  }

  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );

  return (
    <div className="topbar__collapse mt-3">
      <button
        className="topbar__btn topbar__btn--mail topbar__btn--new"
        type="button"
        onClick={collapseMail}
      >
        {/* <MailIcon /> */}
        <div className="bell-icon-notification">
          <span className="lnr lnr-alarm"></span>
          {allLeaves?.length > 0 ? (
            <Badge>{allLeaves?.length}</Badge>
          ) : (
            <Badge>0</Badge>
          )}
        </div>

        {/* <div className="topbar__btn-new-label">
          <div />
        </div> */}
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          aria-label="topbar__back"
          type="button"
          onClick={collapseMail}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__collapse-content">
        <div className="topbar__collapse-title-wrap">
          <p className="topbar__collapse-title">Pending leaves</p>
          {/* <button className="topbar__collapse-button" type="button">Mark all as read</button> */}
        </div>
        {allLeaves?.length > 0 ? (
          allLeaves
            .sort()
            .slice(0, 5)
            ?.map((leave) => (
              <div
                className="topbar__collapse-item flex flex-column align-items-start"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  modalHandler();
                  setEmail(leave);
                  setAttachment(leave);
                  setReliverId(leave?.rfid);
                }}
                key={leave?.id}
              >
                <div className="topbar__collapse-img-wrap">
                  <img
                    className="topbar__collapse-img"
                    src={
                      leave?.user?.images
                        ? leave?.user?.images
                        : "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"
                    }
                    alt=""
                  />
                </div>
                <p className="topbar__collapse-name">{leave?.user?.name}</p>
                <p className="topbar__collapse-message topbar__collapse-message--mail">
                  {leave?.leave_reason?.slice(0, 20)}...
                </p>
                <p className="topbar__collapse-date">
                  {moment(new Date(Number(leave?.createdAt))).fromNow()}
                </p>
              </div>
            ))
        ) : (
          <div className="topbar__collapse-item flex flex-column align-items-start">
            <p className="text-success">There is no pending leave</p>
          </div>
        )}
        <Link
          className="topbar__collapse-link"
          to="/leave/mail"
          onClick={collapseMail}
        >
          See all applications
        </Link>
      </Collapse>
      {/* modal for leave view in details */}
      <Modal toggle={modalHandler} isOpen={modal} backdrop="static">
        <ModalHeader toggle={modalHandler}>
          <div className="inbox__email-left">
            <div className="inbox__email-ava mr-3">
              <img
                src={
                  email?.user?.images
                    ? email?.user?.images
                    : "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"
                }
                alt=""
              />
            </div>
            <div className="inbox__email-info">
              <h5 className="inbox__email-name" style={{ fontSize: 20 }}>
                {email?.user?.name} ({email?.user?.additionalInfo?.office_id})
              </h5>
              <p className="inbox__email-email mt-1" style={{ fontSize: 14 }}>
                {email?.user?.email},{" "}
                {moment(new Date(Number(email?.createdAt))).fromNow()}
              </p>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="flex justify-content-between">
            <h4>
              <b>
                Application for{" "}
                {
                  leaveTypeList?.find(
                    (item) => item?.value == email?.leave_type
                  )?.label
                }{" "}
                {
                  attendanceTypeArray?.find(
                    (item) => item?.value == email?.leave_type
                  )?.label
                }{" "}
                leave
              </b>
            </h4>
            <div>
              {email?.leave_status == "Pending" ||
              email?.leave_status == "Denied" ? (
                <Badge color="danger">{email?.leave_status}</Badge>
              ) : (
                <Badge color="success">{email?.leave_status}</Badge>
              )}
            </div>
          </div>
          <p>
            <b>Leave Start: </b>
            {moment(email?.start_date).format("DD MMMM YYYY")}
          </p>
          <p>
            <b>Leave End: </b>
            {moment(email?.end_date).format("DD MMMM YYYY")}
          </p>
          <p>
            <b>Total: </b>
            {
              IsThereWeekend(
                new Date(email?.start_date),
                new Date(email?.end_date)
              )?.length
            }{" "}
            {IsThereWeekend(
              new Date(email?.start_date),
              new Date(email?.end_date)
            )?.length > 1
              ? "Days"
              : "Day"}
          </p>
          <p>
            <b>Reason:</b> {email?.leave_reason}
          </p>
          {LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
            ?.user && (
            <p className="mt-3">
              <b>
                Reliever: <br />
              </b>
              Name:{" "}
              {
                LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
                  ?.user?.name
              }{" "}
              (
              {
                LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
                  ?.rfid
              }
              ) <br />
              Email:{" "}
              {
                LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
                  ?.user?.email
              }
            </p>
          )}

          {email?.deny_reason ? (
            <div className="mt-3 bg-danger text-white rounded px-3 py-2">
              <h4>The application has been denied!</h4>
              <p>The reason of denied is {email?.deny_reason}</p>
            </div>
          ) : null}
          {Object.keys(attachment)?.length === 0 ? null : JSON.parse(
              email?.images
            )?.length > 0 ? (
            <div>
              <p>
                <b>Attachment ({JSON.parse(email?.images)?.length})</b>
              </p>
              {url?.map((src, index) => (
                <img
                  src={src}
                  onClick={() => openImageViewer(index)}
                  key={index}
                  style={{
                    margin: "2px",
                    height: "50px",
                    width: "auto",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                  }}
                  alt="image"
                />
              ))}
            </div>
          ) : null}
          {isViewerOpen && (
            <ImageViewer
              src={url}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}
        </ModalBody>
        <ModalFooter className="flex justify-content-end align-items-center border-0">
          <ButtonToolbar className="mt-4 justify-content-end">
            <div className="mr-3">
              {permissions?.includes("deny_leave") ? (
                email?.leave_status == "Denied" ||
                email?.leave_status == "Approved" ? null : (
                  <Button
                    onClick={() => {
                      approvedHandler(email, "deny");
                      modalHandler();
                    }}
                    size="sm"
                    color="danger"
                  >
                    Deny
                  </Button>
                )
              ) : null}
            </div>
            <div>
              {permissions?.includes("cancel_leave")
                ? email?.leave_status == "Approved"
                  ? new Date(email?.start_date) > new Date() && (
                      <Button
                        onClick={() => {
                          approvedHandler(email, "cancel");
                          modalHandler();
                        }}
                        size="sm"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    )
                  : null
                : null}
              {permissions?.includes("approve_leave") ? (
                email?.leave_status == "Pending" ? (
                  email?.leave_type == "101" || email?.leave_type == "102" ? (
                    <Button
                      onClick={() => {
                        approvedHandler(email, "wfh_od");
                        modalHandler();
                      }}
                      size="sm"
                      color="primary"
                    >
                      Approve
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        approvedHandler(email, "approve");
                        modalHandler();
                      }}
                      size="sm"
                      color="primary"
                    >
                      Approve
                    </Button>
                  )
                ) : null
              ) : null}
            </div>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TopbarLeaveRequest;
