import { Card, CardBody, Col, Table, Button, Container, Row } from "reactstrap";
import TabPlusIcon from "mdi-react/TabPlusIcon";
import { useHistory } from "react-router";
import LeaveDeductionList from "./components/LeaveDeductionList";
import { decryption } from "../../../HelperFunctions";
import { useEffect, useState } from "react";
import LeaveDeductionForm from "./components/LeaveDeductionForm";
import useLeaveDeductionsQuery from "../../../../GraphQL/hooks/queries/useLeaveDeductionsQuery";
import BreadCrumb from "../../../../shared/components/BreadCrumb/BreadCrumb";

const LeaveDeduction = () => {
  const [leaveDeduction, setLeaveDeduction] = useState({
    leaveDeductionModal: false,
    leaveDeductions: null,
    page: 1,
    size: 10,
  });
  const history = useHistory();

  // ** permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );

  //   ** leave deductions query
  const LoadLeaveDeductionData = useLeaveDeductionsQuery({
    variables: {
      input: {
        page: leaveDeduction?.page,
        size: leaveDeduction?.size,
      },
    },
  });

  //   ** leave deduction modal
  const leaveDeductionToggle = () => {
    setLeaveDeduction((prevState) => ({
      ...prevState,
      leaveDeductionModal: !prevState?.leaveDeductionModal,
    }));
  };

  //   ** set leave durations
  useEffect(() => {
    setLeaveDeduction((prevState) => ({
      ...prevState,
      leaveDeductions: LoadLeaveDeductionData,
    }));
  }, [LoadLeaveDeductionData?.data]);
  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "Leave Deduction ",
    },
  ];
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3 className="page-title">Leave Deduction list</h3>
            </Col>
            <Col md={6}>
              <div className="float-right">
                {permissions?.includes("create_leave_deduction") && (
                  <Button
                    onClick={() => leaveDeductionToggle()}
                    className="btn-sm"
                    color="primary"
                  >
                    <TabPlusIcon />
                    Deduct leave
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            {LoadLeaveDeductionData?.loading ? (
              "Loading..."
            ) : (
              <LeaveDeductionList
                leaveDeduction={leaveDeduction}
                setLeaveDeduction={setLeaveDeduction}
              />
            )}

            {leaveDeduction?.leaveDeductionModal && (
              <LeaveDeductionForm
                modal={leaveDeduction?.leaveDeductionModal}
                toggle={leaveDeductionToggle}
                leaveDeduction={leaveDeduction}
              />
            )}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LeaveDeduction;
