// import React from "react";
// import Skeleton from "react-loading-skeleton";

import { frCA } from "date-fns/locale";

// const SkeletonLoading = ({
//   loading = false,
//   style = { circle: false, width: "100%", height: 15, className: "" },
//   children = <></>,
// }) => {
//   return loading ? (
//     <Skeleton
//       circle={style?.circle}
//       width={style?.width}
//       height={style?.height}
//       containerClassName={style?.className}
//     />
//   ) : (
//     children
//   );
// };

// export default SkeletonLoading;
import React from "react";

export default function SkeletonLoading() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="spinner-border text-success"
        role="status"
        style={{
          width: "4rem",
          height: "4rem",
          borderWidth: "0.4rem",
          animation: "spin 1s linear infinite",
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
      <div
        style={{
          marginTop: "1rem",
          fontSize: "1.2rem",
          color: "black",
          animation: "fadeInOut 2s ease-in-out infinite",
        }}
      >
        Please wait...
      </div>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        @keyframes fadeInOut {
          0%, 100% { opacity: 0.2; }
          50% { opacity: 1; }
        }
      `}</style>
    </div>
  );
}
