import { useState } from "react";
import jwt from "jsonwebtoken";
import axios from "axios";
import moment from "moment";
import {
  attendanceTypeArray,
  leaveTypeListForEmail,
  numberToTextNumber,
} from "./utilities";
import Swal from "sweetalert2";
export const getOidcStorageKey = () => {
  const authSettings = JSON.parse(localStorage?.getItem("authSettings"));
  if (authSettings) {
    return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
  }
  return null;
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {
    const oidc = JSON.parse(localStorage?.getItem(key));
    return oidc;
  }
  return null;
};

export const getToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.id_token;
  }
  return null;
};
export const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data?.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data?.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
};

// weekend date finder
export const IsThereWeekend = (startDate, endDate) => {
  // date format: new Date("2022-03-01");
  let weekendDates = [];
  const initialState = startDate;
  initialState?.setDate(startDate?.getDate() + 1);
  for (
    let date = initialState;
    date < endDate;
    date.setDate(date.getDate() + 1)
  ) {
    if (date.getDay() < 5) {
      weekendDates.push(new Date(date.toString()));
    }
  }
  return weekendDates;
};
// without weekend date
export const withoutWeekendDates = (startDate, endDate) => {
  // date format: new Date("2022-03-01");
  let weekendDates = [];
  const initialState = startDate;
  initialState?.setDate(startDate?.getDate());
  for (
    let date = initialState;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    if (date.getDay() < 5) {
      weekendDates.push(moment(new Date(date.toString())).format("YYYY-MM-DD"));
    }
  }
  return weekendDates;
};

// token decoder
export const customerInfo = () => {
  let info;
  const token = localStorage?.getItem("token");
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    info = decoded;
  });
  return info;
};
// token decoder
export const loginInfo = () => {
  let info;
  const token = localStorage?.getItem("token");
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    info = decoded;
  });
  return info;
};

// custom table index in new array
export const arrayCustomIndexing = (array) => {
  if (array?.length) {
    return array?.map((item, index) => ({
      ...item,
      tableIndex: index + 1,
    }));
  }
};

// text shortend by words with coustom lenght
export const textToWords = (text, length) => {
  return `${text
    ?.split(" ")
    ?.slice(0, length ? length : 5)
    ?.join(" ")}...`;
};

// check box json strigify value retirved
export const checkBoxValueRetrive = (value) => {
  let array = [];
  value &&
    JSON.parse(value).map((item) => {
      Object.keys(item).some(function (k) {
        if (item[k] === true) {
          array.push(k);
        }
      });
    });
  return array;
};

// remove duplicate year
export const removeDuplicateYear = (arrayOfYears) => {
  const yearDesc = arrayOfYears?.sort(function (a, b) {
    return b - a;
  });
  return [...new Set(yearDesc)];
};

// get all user attendance
export const userAttendenceObject = (item, index) => ({
  indexId: index + 1,
  attendance: item?.attendanceInfo?.length ? true : false,
  attendanceInfo: item?.attendanceInfo[0],
  employeeInfo: {
    designationInfo: {
      name: item?.additionalInfo?.designationInfo?.name,
    },
    user: {
      name: item?.name,
    },
    user_id: 18,
  },
  isAuthorized: item?.attendanceInfo[0]?.isAuthorized,
  id: item?.attendanceInfo[0]?.id,
  login_date: item?.attendanceInfo[0]?.login_date,
  login_device: item?.attendanceInfo[0]?.login_device,
  login_time: item?.attendanceInfo[0]?.login_time,
  logout_device: item?.attendanceInfo[0]?.logout_device,
  logout_time: item?.attendanceInfo[0]?.logout_time,
  reason: item?.attendanceInfo[0]?.reason,
  rfid: item?.additionalInfo?.rfid,
  type: item?.attendanceInfo[0]?.type,
  updatedAt: item?.attendanceInfo[0]?.updatedAt,
  user_ip: item?.attendanceInfo[0]?.user_ip,
  useragent: item?.attendanceInfo[0]?.useragent,
});

// checking penalty
export const penaltyChecking = async (
  checkStart,
  checkEnd,
  user_id,
  loadPenalty
) => {
  let penaltyDates = [];
  if (checkStart !== checkEnd) {
    const initialState = new Date(checkStart);
    initialState.setDate(new Date(checkStart).getDate());
    for (
      let date = initialState;
      date <= new Date(checkEnd);
      date.setDate(date.getDate() + 1)
    ) {
      const result = await loadPenalty?.refetch({
        user_id,
        penalty_date: moment(new Date(date.toString())).format("YYYY-MM-DD"),
      });
      if (
        result?.data?.getAllPenaltyByUserIdAndDate?.penalty_date &&
        result?.data?.getAllPenaltyByUserIdAndDate?.penalty_status == 1001
      ) {
        penaltyDates.push({
          id: result?.data?.getAllPenaltyByUserIdAndDate?.id,
          penalty_date:
            result?.data?.getAllPenaltyByUserIdAndDate?.penalty_date,
          penalty_status:
            result?.data?.getAllPenaltyByUserIdAndDate?.penalty_status,
        });
      }
    }
  } else {
    const result = await loadPenalty?.refetch({
      user_id,
      penalty_date: moment(new Date(checkStart)).format("YYYY-MM-DD"),
    });
    if (
      result?.data?.getAllPenaltyByUserIdAndDate?.penalty_date &&
      result?.data?.getAllPenaltyByUserIdAndDate?.penalty_status == 1001
    ) {
      penaltyDates.push({
        id: result?.data?.getAllPenaltyByUserIdAndDate?.id,
        penalty_date: result?.data?.getAllPenaltyByUserIdAndDate?.penalty_date,
        penalty_status:
          result?.data?.getAllPenaltyByUserIdAndDate?.penalty_status,
      });
    }
  }
  return penaltyDates;
};
// make date range array
export const dateRangeArray = (startDate, endDate) => {
  let arrayOfDates = [];
  if (startDate !== endDate) {
    const initialState = new Date(startDate);
    initialState.setDate(new Date(startDate).getDate());
    for (
      let date = initialState;
      date <= new Date(endDate);
      date.setDate(date.getDate() + 1)
    ) {
      arrayOfDates.push(moment(new Date(date.toString())).format("YYYY-MM-DD"));
    }
  } else {
    arrayOfDates.push(moment(new Date(startDate)).format("YYYY-MM-DD"));
  }
  return arrayOfDates;
};

// approval email template
export const approvalLeaveEmail = (html) => {
  return `
  <!DOCTYPE html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1">
    </head>
    <body>
      <p>Dear ${html?.applicant_name?.split(" ")[0]},</p>
      <p>Your application has been approved.</p>
      <p>Best Regards,</p>
      <p>${html?.approval_name}</p>
      <p>&mdash;</p>
      <p>On ${html?.leave_date} <i>${html?.applicant_name}&#60;${
    html?.applicant_email
  }&#62;</i> wrote:</p>
      <p>Start: ${html?.start_date}</p>
      <p>End: ${html?.end_date}</p>
      <p>Total: ${html?.total_day}${html?.total_day > 1 ? " Days" : " Day"}</p>
      <p>The reason of leave: <br /> ${html?.leave_reason}</p>
    </body>
  </html>`;
};

export const checkWithDate = (array) => {
  // array format
  // const array = [
  //   {
  //     start_date: "2022-02-16",
  //     end_date: "2022-02-16",
  //     id: 1,
  //   }
  // ];
  const finalArray = [];
  const tempArray = array?.map((item) => {
    const startDate = moment(new Date(item?.start_date)).format("YYYY-MM-DD");
    const endDate = moment(new Date(item?.end_date)).format("YYYY-MM-DD");
    let arrayOfDates = [];
    if (startDate !== endDate) {
      const initialState = new Date(startDate);
      initialState.setDate(new Date(startDate).getDate());
      for (
        let date = initialState;
        date <= new Date(endDate);
        date.setDate(date.getDate() + 1)
      ) {
        arrayOfDates.push({
          application_date: moment(new Date(date.toString())).format(
            "YYYY-MM-DD"
          ),
          application_id: item?.id,
        });
      }
    } else {
      arrayOfDates.push({
        application_date: moment(new Date(startDate)).format("YYYY-MM-DD"),
        application_id: item?.id,
      });
    }
    return arrayOfDates;
  });

  tempArray?.map((item) => {
    item?.map((item2) => {
      finalArray.push(item2);
    });
  });
  return finalArray;
};

// exist leave or attendance application warning
export const isExistApllication = (existApplicationId, totalApplication) => {
  const existLeave = [];
  [...new Set(existApplicationId)]?.map((existId) => {
    totalApplication?.map((item) => {
      if (item?.id == existId) {
        existLeave.push({
          name:
            attendanceTypeArray?.find((type) => type?.value == item?.leave_type)
              ?.label ||
            leaveTypeListForEmail?.find(
              (type) => type?.value == item?.leave_type
            )?.label,
          status: item?.leave_status,
          start: moment(new Date(item?.start_date)).format("YYYY-MM-DD"),
          end: moment(new Date(item?.end_date)).format("YYYY-MM-DD"),
        });
      }
    });
  });
  Swal.fire(
    `Exist!`,
    `${numberToTextNumber[existLeave?.length]} ${
      existLeave?.length > 1 ? "applications" : "application"
    } already exist within your selected date. Try another date. <br/>
      <table style="border-collapse: collapse; margin: 0 auto; font-size: 10px; font-family: sans-serif; box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);">
          <thead>
              <tr style="background-color: #009879; color: #ffffff; text-align: left;">
                  <th style="padding: 12px 15px;">#</th>
                  <th style="padding: 12px 15px;">Applied For</th>
                  <th style="padding: 12px 15px;">Start</th>
                  <th style="padding: 12px 15px;">End</th>
                  <th style="padding: 12px 15px;">Day</th>
                  <th style="padding: 12px 15px;">Status</th>
              </tr>
          </thead>
          <tbody>
              ${
                existLeave?.length > 0
                  ? existLeave?.map(
                      (item, index) =>
                        `
          <tr style="border-bottom: 1px solid #dddddd;">
              <td style="padding: 12px 15px;">${index + 1}</td>
              <td style="padding: 12px 15px;">${item?.name}</td>
              <td style="padding: 12px 15px;">${moment(item?.start).format(
                "DD MMMM YYYY"
              )}</td>
              <td style="padding: 12px 15px;">${moment(item?.end).format(
                "DD MMMM YYYY"
              )}</td>
              <td style="padding: 12px 15px;">${
                withoutWeekendDates(
                  new Date(moment(item?.start).format("YYYY-MM-DD")),
                  new Date(moment(item?.end).format("YYYY-MM-DD"))
                )?.length
              } ${
                          withoutWeekendDates(
                            new Date(moment(item?.start).format("YYYY-MM-DD")),
                            new Date(moment(item?.end).format("YYYY-MM-DD"))
                          )?.length > 1
                            ? " Days"
                            : " Day"
                        }</td>
              <td style="padding: 12px 15px;">${item?.status}</td>
          </tr>      
        `
                    )
                  : null
              }
          </tbody>
      </table>`,
    "error"
  );
};

export function countWeekends(startDate, endDate) {
  // const startDate = new Date('2023-02-01');
  // const endDate = new Date('2023-02-28');
  // const weekends = countWeekends(startDate, endDate);
  let count = 0;
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek === 5 || dayOfWeek === 6) {
      count++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return count;
}

export const dateFormat = (milliseconds, format) => {
  return milliseconds
    ? moment(new Date(Number(milliseconds))).format(
        format ?? "DD/MM/YY, hh:mm a"
      )
    : "-";
};

export const labelName = (array, id, label = "label") => {
  return array?.find((item) => item?.value == id)?.[label] ?? "-";
};

export const dateTime = (milliseconds, format) => {
  return milliseconds
    ? moment(new Date(Number(milliseconds))).format(
        format ?? "DD/MM/YY, hh:mm a"
      )
    : "-";
};

export const timeToMiliseconds = (time) => {
  const data = moment.duration(time).asMilliseconds();
  return data;
};

export const onlyTime = (h = 0, m = 0, s = 0) => {
  return `${h}h ${m}m ${s}s`;
};

export const pluralForm = (number = 0, string = "") =>
  `${number} ${string} ${number > 1 ? "s" : ""}`;

export const ageCalculator = (
  birth_date = new Date(),
  current_date = new Date()
) => {
  // Get the birthdate and current date
  const birthdate = moment(birth_date); // Replace with the actual birthdate and time in 'YYYY-MM-DDTHH:mm:ss' format
  const currentDate = moment(current_date);
  // Calculate the difference between birthdate and current date
  const duration = moment.duration(currentDate.diff(birthdate));

  // Extract years, months, days, hours, minutes, and seconds
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  };
};

/** object to query params*/
export const objectToQueryParams = (obj) => {
  const searchParams = new URLSearchParams(obj);
  const queryString = searchParams.toString();
  return queryString;
};

/** params to object */
export const paramsToObject = (params) => {
  const searchParams = new URLSearchParams(params);
  const paramsObject = Object.fromEntries(searchParams.entries());
  return paramsObject;
};
