import { renderMaskedField } from "@/shared/components/form/FormField";
import { SelectField } from "@/shared/components/form/Select";
import images from "@/shared/img/uploader/placeholder.png";
import { useMutation, useQuery } from "@apollo/client";
import { css } from "@emotion/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import MoonLoader from "react-spinners/MoonLoader";
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import { UPDATE_USER } from "../../GraphQL/Mutation";
import {
  LOAD_DISTRICT,
  LOAD_DIVISION,
  LOAD_UNION,
  LOAD_UPAZILLA,
} from "../../GraphQL/Queries";
import BASE_URL from "../../utils/api/baseUrl";
import { FormField } from "./FormField";

const AgentProfileUpdate = ({ isHorizontal, isAboveError }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // retrive data from local storage
  const userData = history?.location?.state?.user;
  const userID = history?.location?.state?.id;
  const farmer = history.location?.state?.farmers;

  const getDivision = JSON.parse(localStorage?.getItem("division"))?.find(
    (data) => data?.id == userData?.division
  );
  const getDistrict = JSON.parse(localStorage?.getItem("district"))
    ?.filter((data) => data?.division_id == userData?.division)
    ?.find((data) => data?.id == userData?.district);

  const getUpazilla = JSON.parse(localStorage?.getItem("upazilla"))
    ?.filter((data) => data?.district_id == userData?.district)
    ?.find((data) => data?.id == userData?.upazilla);

  const getUnion = JSON.parse(localStorage?.getItem("union"))
    ?.filter((data) => data?.upazilla_id == userData?.upazilla)
    ?.find((data) => data?.id == userData?.union);

  const [preview, setPreview] = useState("");
  const [image, setImage] = useState(userData?.images);
  const [division, setDivision] = useState([]);
  const [district, setDistrict] = useState([]);
  const [upazilla, setUpazilla] = useState([]);
  const [union, setUnion] = useState([]);

  // update mutatiion
  const [updateUser, updateUserStatus] = useMutation(UPDATE_USER);

  let defaultDivision = JSON.parse(localStorage?.getItem("division"))?.find(
    (division) => division?.id == farmer?.division
  );
  let defaultDistrict = JSON.parse(localStorage?.getItem("district"))?.find(
    (district) =>
      district?.id == farmer?.district &&
      defaultDivision?.id == district?.division_id
  );

  let defaultUpazilla = JSON.parse(localStorage?.getItem("upazilla"))?.find(
    (upazilla) => upazilla?.id == farmer?.upazilla
  );
  let defaultUnion = JSON.parse(localStorage?.getItem("union"))?.find(
    (union) => union?.id == farmer?.union
  );

  const {
    handleSubmit,
    watch,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  //   address field
  const divisionQuery = useQuery(LOAD_DIVISION);
  const districtQuery = useQuery(LOAD_DISTRICT, {
    variables: {
      division_id: Number(watch("division")?.value),
    },
  });
  const upazillaQuery = useQuery(LOAD_UPAZILLA, {
    variables: {
      district_id: Number(watch("district")?.value),
    },
  });

  const unionQuery = useQuery(LOAD_UNION, {
    variables: {
      upazilla_id: Number(watch("upazilla")?.value),
    },
  });

  const divisionId = watch("division")?.value;
  const districtId = watch("district")?.value;
  const upazillaId = watch("upazilla")?.value;
  useEffect(() => {
    if (divisionQuery.data?.getDivision?.length > 0) {
      setDivision(divisionQuery?.data?.getDivision);
    }
  }, [divisionQuery?.data?.getDivision?.length]);

  useEffect(() => {
    if (districtQuery?.data?.getDistrictById?.length > 0) {
      setDistrict(districtQuery?.data?.getDistrictById);
    }
  }, [divisionId, districtQuery?.data?.getDistrictById]);

  useEffect(() => {
    if (upazillaQuery.data?.getUpazillaById?.length > 0) {
      setUpazilla(upazillaQuery?.data?.getUpazillaById);
    }
  }, [districtId, upazillaQuery?.data?.getUpazillaById]);
  useEffect(() => {
    if (unionQuery.data?.getUnionById?.length > 0) {
      setUnion(unionQuery?.data?.getUnionById);
    }
  }, [upazillaId, unionQuery?.data?.getUnionById]);

  // final submit form
  const onSubmit = async (data) => {
    await updateUser({
      variables: {
        id: Number(userID),
        name: data?.name,
        phone: data?.mobile,
        district: Number(data?.district?.value),
        division: Number(data?.division?.value),
        upazilla: Number(data?.upazilla?.value),
        union: Number(data?.union?.value),
        images: image,
        username: data?.username,
        password: data?.password ? data?.password : userData?.password,
        email: data?.email,
      },
    });
    history.push("/profile/info");
    location.reload();
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const files = [...e.target.files];
    setPreview(files[0]);
    formData.append("images", files[0]);

    if (farmer?.image) {
      let res = await axios.post(
        `${BASE_URL}/api/image/delete`,
        { oldImage: farmer?.image },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res?.data) {
        const file = await axios.post(
          `${BASE_URL}/api/image/users/upload`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
        setImage(file?.data?.data);
      }
    } else {
      const file = await axios.post(
        `${BASE_URL}/api/image/users/upload`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      setImage(file?.data?.data);
    }
  };

  const setAndClearFields = (setValue, mainField, mainValue, fieldsToClear) => {
    setValue(mainField, mainValue);

    fieldsToClear.forEach((field) => setValue(field, ""));
  };
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <form
            className={`form ${isHorizontal && "form--horizontal"}`}
            style={{ width: "100%" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Col md={8} className="mx-auto">
              <div className="form__form-group">
                <div className="input__container">
                  {image ? (
                    <img
                      src={image}
                      style={{ width: "100px" }}
                      className="image-box"
                      alt=""
                    />
                  ) : preview ? (
                    <img
                      src={URL.createObjectURL(preview)}
                      className="image-box"
                      style={{ width: "100px" }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={userData?.images ? userData?.images : images}
                      style={{ width: "100px" }}
                      className="image-box"
                      alt=""
                    />
                  )}
                  <input
                    name="images"
                    id="images"
                    className="input--box"
                    type="file"
                    {...register("images")}
                    onChange={handleChange}
                  />
                </div>
                <div style={{ width: "200px", margin: "10px  auto 0 auto" }}>
                  {errors?.images && (
                    <span style={{ color: "red" }}>image is required</span>
                  )}
                </div>
              </div>

              <Row>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Full Name</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="name"
                        control={control}
                        component="input"
                        errors={errors}
                        rules={{ required: "This is required field" }}
                        defaultValue={userData?.name}
                        isAboveError={isAboveError}
                        placeholder="Name"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Username</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="username"
                        control={control}
                        component="input"
                        errors={errors}
                        rules={{ required: "This is required field" }}
                        defaultValue={userData?.username}
                        disabled
                        isAboveError={isAboveError}
                        placeholder="Username"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Mobile Number
                    </span>
                    <div className="form__form-group-field">
                      <FormField
                        name="mobile"
                        control={control}
                        // component="input"
                        component={renderMaskedField}
                        mask={[
                          "(",
                          "+",
                          "8",
                          "8",
                          ")",
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        errors={errors}
                        rules={{ required: "This is required field" }}
                        defaultValue={userData?.phone}
                        isAboveError={isAboveError}
                        placeholder="(+88) __ __ _"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="email"
                        control={control}
                        component="input"
                        errors={errors}
                        rules={{ required: "This is required field" }}
                        defaultValue={userData?.email}
                        isAboveError={isAboveError}
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Division</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="division"
                        control={control}
                        component={SelectField}
                        errors={errors}
                        options={division?.map((data) => ({
                          value: data?.id,
                          label: data?.name,
                        }))}
                        onChange={(e) => {
                          setAndClearFields(setValue, "division", e, [
                            "district",
                            "upazilla",
                            "union",
                          ]);
                        }}
                        rules={{ required: "This is required field" }}
                        defaultValue={{
                          value: defaultDivision?.id
                            ? defaultDivision?.id
                            : getDivision?.id,
                          label: defaultDivision?.name
                            ? defaultDivision?.name
                            : getDivision?.name,
                        }}
                        // value="dhjs"
                        isAboveError={isAboveError}
                        placeholder="Division"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">District</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="district"
                        control={control}
                        component={SelectField}
                        errors={errors}
                        options={district?.map((data) => ({
                          value: data?.id,
                          label: data?.name,
                        }))}
                        onChange={(e) => {
                          setAndClearFields(setValue, "district", e, [
                            "upazilla",
                            "union",
                          ]);
                        }}
                        rules={{ required: "This is required field" }}
                        defaultValue={{
                          value: defaultDistrict?.id
                            ? defaultDistrict?.id
                            : getDistrict?.id,
                          label: defaultDistrict?.name
                            ? defaultDistrict?.name
                            : getDistrict?.name,
                        }}
                        isAboveError={isAboveError}
                        placeholder="District"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Upazilla</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="upazilla"
                        control={control}
                        component={SelectField}
                        errors={errors}
                        options={upazilla?.map((data) => ({
                          value: data?.id,
                          label: data?.name,
                        }))}
                        onChange={(e) => {
                          setAndClearFields(setValue, "upazilla", e, ["union"]);
                        }}
                        rules={{ required: "This is required field" }}
                        defaultValue={{
                          value: defaultUpazilla?.id
                            ? defaultUpazilla?.id
                            : getUpazilla?.id,
                          label: defaultUpazilla?.name
                            ? defaultUpazilla?.name
                            : getUpazilla?.name,
                        }}
                        isAboveError={isAboveError}
                        placeholder="Upazilla"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Union</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="union"
                        control={control}
                        component={SelectField}
                        errors={errors}
                        options={union?.map((data) => ({
                          value: data?.id,
                          label: data?.name,
                        }))}
                        rules={{ required: "This is required field" }}
                        defaultValue={{
                          value: defaultUnion?.id
                            ? defaultUnion?.id
                            : getUnion?.id,
                          label: defaultUnion?.name
                            ? defaultUnion?.name
                            : getUnion?.name,
                        }}
                        isAboveError={isAboveError}
                        placeholder="union"
                      />
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" type="submit">
                        {userID ? "Update" : "Create"}
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          history.push(`/profile/info`);
                        }}
                      >
                        Cancel
                      </Button>
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AgentProfileUpdate;
